import React from 'react';
import { IonGrid, IonContent, IonSlides, IonSlide, IonRow, IonCol } from '@ionic/react';
import './Tutorial.scss';

import { ReactComponent as WheelChairAccessible } from 'assets/img/accessibility/wheelchair-accessible-outline.svg';
import { ReactComponent as WheelchairInAccessible } from 'assets/img/accessibility/wheelchair-inaccessible-outline.svg';
import { ReactComponent as WheelChairAccessibleUnknown } from 'assets/img/accessibility/wheelchair-unknown-outline.svg';
import { ReactComponent as WheelChairAccessiblePartial } from 'assets/img/accessibility/wheelchair-partial-outline.svg';

interface OwnProps {
  slideRef: any,
  SlideButtons: any
}

interface TutorialProps extends OwnProps { }

const TutorialScotland: React.FC<TutorialProps> = ({ slideRef, SlideButtons }) => {

  return (
    <IonContent fullscreen forceOverscroll={false}>
      <IonSlides ref={slideRef} pager={true} scrollbar={true} options={{ initialSlide: 0 }}>
        <IonSlide>
          <div className="tutorial-slide">
            <h2 className="slide-title">Welcome to <b>Scotland</b></h2>
            <img src="assets/img/tutorial/scotland/TUTORIAL-1.svg" alt="" className="slide-image" />
            <p>Get the best wheelchair-accessible directions</p>
          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>
        <IonSlide>
          <div className="tutorial-slide">
            <h2>Wheelchair-friendly</h2>
            <img src="assets/img/tutorial/scotland/TUTORIAL-2.svg" alt="" className="slide-image" />

            <IonGrid className="bottom-icon-grid">
              <IonRow>
                <IonCol>
                  <div className="travel-info-row">
                    <div className='icon-left'>
                      <WheelChairAccessible className="icon-right-margin" width={20} height={20} />
                    </div>
                    <div className='text-right'><span>Accessible</span></div>
                  </div>
                </IonCol>
                <IonCol>
                  <div className="travel-info-row">
                    <div className='icon-left'>
                      <WheelchairInAccessible className="icon-right-margin" width={20} height={20} />
                    </div>
                    <div className='text-right'><span>Inaccessible</span></div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="travel-info-row">
                    <div className='icon-left'>
                      <WheelChairAccessiblePartial className="icon-right-margin" width={20} height={20} />
                    </div>
                    <div className='text-right'><span>See Notes</span></div>
                  </div>
                </IonCol>
                <IonCol>
                  <div className="travel-info-row">
                    <div className='icon-left'>
                      <WheelChairAccessibleUnknown className="icon-right-margin" width={20} height={20} />
                    </div>
                    <div className='text-right'><span>Unknown</span></div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>
        <IonSlide >
          <div className="tutorial-slide">
            <h2>Stops & Services</h2>
            <img src="assets/img/tutorial/scotland/TUTORIAL-3.svg" alt="" className="slide-image" />
            <p>Easily see which stops and which services are accessible, and the next departure times</p>
          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>
        <IonSlide>
          <div className="tutorial-slide">
            <h2>Multi-modal Directions</h2>
            <img src="assets/img/tutorial/scotland/TUTORIAL-4.svg" alt="" className="slide-image" />
            <p>Combine multiple modes in a single trip for more travel options</p>
          </div>
          <SlideButtons isFinalSlide={true} />
        </IonSlide>
      </IonSlides>
    </IonContent>
  );
};

export default TutorialScotland;
