import {useEffect} from 'react';
import {useLocation} from 'react-router';

export const GA_CATEGORY_UNKNOWN_ACCESSIBILITY = 'unknown wheelchair accessibility';
export const GA_CATEGORY_WHEELCHAIR_INACCESSIBLE = 'wheelchair inaccessible';
export const GA_ACTION_STOP = 'stop';
export const GA_ACTION_ROUTE = 'route';
/*
  Google Analytics.js references
  1. https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
  2. https://developers.google.com/analytics/devguides/collection/upgrade/analyticsjs
  3. https://trello.com/c/SEPUctEj/296-automatically-record-unknown-accessibility-inaccessibility
 */
// Load Google Analytics for production only.
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-unused-expressions
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
} else {
  window.ga = console.log;
}

window.ga('create', 'UA-181262216-1', 'auto');
window.ga('send', 'pageview');

export default function Analytics() {
  const location = useLocation();

  useEffect(() => {
    window.ga('send', 'pageview', location.pathname + location.search);
  }, [location]);

  return null;
}

export function sendEvent(category, action, label) {
  window.ga('send', 'event', category, action, label);
}
