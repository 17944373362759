import React from "react";
import {IonIcon} from "@ionic/react";
import {chevronForward} from "ionicons/icons";
import {getIcon} from "./Icon";
import "./Segments.scss";
import {WheelChairAccessibilityIcon} from "./WheelChairAccessibility";
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {useTransportOptionsState} from 'util/transport-options';
const getDurationInMinutes = (segment) => {
  return Math.round((segment.endTime - segment.startTime) / 60);
};

const isMainSegment = (segment, trip) => {
  return segment.template.hashCode === trip.mainSegmentHashCode;
};

// if a segment is stationary or walking, it doesn't really feel like part of the trip
const isInsignificantMode = (segment) => {
  return segment.template.modeInfo.identifier.startsWith("stationary_") || segment.template.modeInfo.identifier.startsWith("wa_");
};

const isWheelchairMode = (segment) => segment.template.modeInfo.identifier === "wa_whe";

const isPublicTransit = (segment) => {
  return segment.template.modeInfo.identifier.startsWith("pt_");
};

export const Segments = function ({trip}) {
  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();

  const isSegmentVisible = (segment, index) => {
    if (isMainSegment(segment, trip)) {
      return true;
    }
    if (isInsignificantMode(segment) && getDurationInMinutes(segment) <= 3 && index === 0) {
      return false;
    } else if (isInsignificantMode(segment) && getDurationInMinutes(segment) <= 2) {
      return false;
    } else {
      return true;
    }
  };

  const getSegment = (segment, index, array) => {
    const FilteredSegmentsData = array.map(ele => isWheelchairMode(ele) || isPublicTransit(ele))
    const isAccessibilityInfoVisible = FilteredSegmentsData?.some(ele => ele === true)

    //finds if current segment is last in trip or not
    const isSegmentLastInTrip = index === array.length - 1;
    if (isPublicTransit(segment)) {
      return (
        <div className="segment" key={index}>
          <div className="segment-detail">
            <div className="segment-detail-inner">
              <div className="icon-n-number">
                {getIcon(segment.template.modeInfo)}
                <span className="service-number">{segment.serviceNumber}</span>
              </div>
              {getDurationInMinutes(segment)}min
            </div>
            {!isSegmentLastInTrip && <IonIcon className="arrowIcon" icon={chevronForward}/>}
          </div>
          {isAccessibilityInfoVisible && shouldShowWheelchairAccessibility && <div className="accessibility-info" data-is-last-segment-in-trip={isSegmentLastInTrip}>
                      <WheelChairAccessibilityIcon wheelchairAccessibility={segment.boardingStopAccessibility} iconStyle="icon"/>
            <div className="accessible-line" data-accessible={segment.vehicleWheelchairAccessibility}/>
            <WheelChairAccessibilityIcon wheelchairAccessibility={segment.alightingStopAccessibility} iconStyle="icon"/>
          </div>}
        </div>
      );
    } else if (array.length === 1) {
      return (
        <div className="segment" key={index} data-single-segment-trip={true}>
          <div className="segment-detail">
            <div className="segment-detail-inner">
              {getIcon(segment.template.modeInfo)}
            </div>
            {!isSegmentLastInTrip && <IonIcon className="arrowIcon" icon={chevronForward}/>}
          </div>
          {isAccessibilityInfoVisible && shouldShowWheelchairAccessibility && <div className="accessibility-info" data-is-last-segment-in-trip={true}>
            <div className="accessible-line" data-accessible={true}/>
          </div>}
        </div>
      );
    } else {
      return (
        <div className="segment" key={index}>
          <div className="segment-detail">
            <div className="segment-detail-inner">
              {getIcon(segment.template.modeInfo)}
              {getDurationInMinutes(segment)}min
            </div>
            {!isSegmentLastInTrip && <IonIcon className="arrowIcon" icon={chevronForward}/>}
          </div>
         {isAccessibilityInfoVisible && shouldShowWheelchairAccessibility && <div className="accessibility-info" data-is-last-segment-in-trip={isSegmentLastInTrip}>
            <div className="accessible-line" data-accessible={true}/>
          </div>}
        </div>
      )
    }
  };

  const segments = trip.segments.filter(isSegmentVisible).map(getSegment);

  return <div className="segments">{segments}</div>;
};
