function scroll(scrollingBox, direction, end, percentRegressedFunction) {
    const start = scrollingBox[direction];
    const timeStart = performance.now();
    requestAnimationFrame(step);

    function step(timestamp) {
        const timeProgressed = timestamp - timeStart;
        const percentRegressed = percentRegressedFunction(timeProgressed);
        if (timeProgressed < 250) {
            scrollingBox[direction] = percentRegressed * start + (1 - percentRegressed) * end;
            requestAnimationFrame(step);
        } else {
            scrollingBox[direction] = end;
        }
    }
}

export function linearScroll(scrollingBox, direction, end) {
    scroll(scrollingBox, direction, end, timeProgressed => 1 - timeProgressed / 250);
}

export function sineScroll(scrollingBox, direction, end) {
    scroll(scrollingBox, direction, end, timeProgressed => (Math.cos(timeProgressed * Math.PI / 250) + 1) / 2);
}
