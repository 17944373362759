import {Plugins} from '@capacitor/core';

import {SharedStateTarget} from './callbacks';
import {useEffect} from 'react';

const {Storage} = Plugins;
const cache = {};

/**
 * Initialize cache from storage or with default
 * doesn't save default to storage, but puts it in the cache for sync get
 * @param {string} key - Key to initialize
 * @param {object} def - Default value to use
 * @param {string} prevVerKey - Key name of previous version from where to migrate
 * @param {function} callbackToMigrate - Callback function to call if data for previous version is found.
 *
 */
export const init = async (key, def, prevVerKey, callbackToMigrate) => {
  const getAndParseFromStorage = async (keyName) => {
    const value = (await Storage.get({key: keyName})).value;
    return value !== null ? JSON.parse(value) : null;
  };

  const prevVerValue = prevVerKey ? await getAndParseFromStorage(prevVerKey) : null;
  if (prevVerValue) {
    const migratedValue = callbackToMigrate(prevVerValue);
    remove(prevVerKey); // clean previous version after migration
    if (migratedValue) {
      set(key, migratedValue); // cache and save migrated version
      return;
    }
  }

  const value = await getAndParseFromStorage(key); // capacitor returns null for values not in storage
  cache[key] = value !== null ? value : def;
};

/**
 * Retrieve data from cache. Be sure to call init first
 * @param {string} key - Key to get data for
 */
export const get = (key) => {
  console.assert(key in cache, `did you forget to init('${key}')?`);
  return cache[key];
};

/**
 * Set data to cache and storage.
 * @param {string} key - Key to save data for
 * @param {object} value - data to save against key
 */
export const set = (key, value) => {
  cache[key] = value;
  // noinspection JSIgnoredPromiseFromCall
  Storage.set({key, value: JSON.stringify(value)});
  return value;
}

/**
 * Remove key and data from cache and storage
 * @param {string} key - Key to remove
 */
export const remove = (key) => {
  delete cache[key];
  Storage.remove({key});
};

/* eslint-disable react-hooks/rules-of-hooks */
export class SharedStorageStateTarget extends SharedStateTarget {
  constructor(key, def) {
    super(def);
    this.key = key;
    init(key, def);
  }

  useSharedState() {
    const [state, setState] = super.useSharedState();
    const setStorageState = nextState => setState(set(this.key, nextState));

    useEffect(() => {
      setState(get(this.key));
    }, []);

    return [state, setStorageState];
  }
}
