import React from 'react';
import {IonPage, useIonViewDidEnter} from '@ionic/react';
import {Plugins} from '@capacitor/core';
import {documentTitle} from "../data/apps/config";
import {set} from '../util/storage';

export default function JynPage({children, title, canResume = true, ...props}) {
  // hide the splash screen after the page has loaded

  useIonViewDidEnter(() => {
    if(!title && process.env.NODE_ENV === 'development'){
      throw new Error("Document title needs to be defined");
    }
    document.title = title + ' - ' + documentTitle;
    Plugins.SplashScreen.hide()
    //note : useLocation is returning /tutorial for MapView, so we use window.location.pathname instead of uselocation().
    if (canResume && window.location.pathname !== '/') {
      set('lasturl', window.location.pathname);
    }
  }, []);

  // Ionic Lifecycle Events only occur on components that render IonPage
  return <IonPage {...props}>{children}</IonPage>;
};
