import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from '@ionic/react';
import {compassOutline, bugOutline, mapSharp, timeOutline} from 'ionicons/icons';
import {IonReactRouter} from '@ionic/react-router';

import Menu from './components/Menu';
import {set, get, init} from 'util/storage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {connect} from './data/connect';
import {AppContextProvider} from './data/AppContext';
import {loadConfData} from './data/sessions/sessions.actions';
import {loadUserData, setIsLoggedIn, setUsername} from './data/user/user.actions';
import Account from './pages/Account';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Support from './pages/Support';
import SchedulePage from './pages/SchedulePage';
import SpeakerList from './pages/SpeakerList';
import SpeakerDetail from './pages/SpeakerDetail';
import SessionDetail from './pages/SessionDetail';
import MapView from './pages/MapView';
import About from './pages/About';
import Tutorial from 'pages/Tutorial';
import HomeOrTutorial from './components/HomeOrTutorial';
import {Schedule} from "./models/Schedule";
import {SetAnimatedContext} from "./util/contexts";
import Analytics from "./components/Analytics";
import DirectionsDetails from "./pages/DirectionsDetails";
import DirectionsList from "./pages/DirectionsList";
import AppUrlListener from 'AppUrlListener';
import {setFilterInitially} from 'util/stop-list-filter';
import {appName} from "./data/apps/config";

import './App.css'

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected/>
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  schedule: Schedule;
  menuEnabled: boolean;
}

interface DispatchProps {
  loadConfData: typeof loadConfData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps { }

// initialize 'isBottomMenuEnabled' value
init('isBottomMenuEnabled', true);

const IonicApp: React.FC<IonicAppProps> = ({darkMode, schedule, menuEnabled, setIsLoggedIn, setUsername, loadConfData, loadUserData}) => {
  // ionic tries to animate when using history.replace with the same path, so we need a way to disable it
  const [animated, setAnimated] = React.useState(true);

  const [isBottomMenuEnabled, realSetIsBottomMenuEnabled] = React.useState<boolean>(true);

  const setIsBottomMenuEnabled = (isBottomMenuEnabled: boolean) => {
    realSetIsBottomMenuEnabled(isBottomMenuEnabled);
    // set 'isBottomMenuEnabled' value in storage
    set('isBottomMenuEnabled', isBottomMenuEnabled);
  }

  const setFavicon = () => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", `${process.env.PUBLIC_URL}/assets/icon/favicon_${appName}.svg`);
  }

  useEffect(() => {
    setFavicon();
    loadUserData();
    loadConfData();
    // set value of 'isBottomMenuEnabled' after getting it from storage
    // used for setting the value after page refresh
    realSetIsBottomMenuEnabled( get('isBottomMenuEnabled'));
    // eslint-disable-next-line
  }, []);

  const handleBugClick = () => {
    Ybug.open('annotate');
  }

  return (
    schedule.groups.length === 0 ? (
      <div/>
    ) : (
      <SetAnimatedContext.Provider value={setAnimated}>
        <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
          <IonReactRouter>
            <AppUrlListener/>
            <Analytics/>
            {/*IonSplitPane should have exactly two children: 1) left side bar; and 2) contentId area*/}
            <IonSplitPane contentId="main">
              <Menu isBottomMenuEnabled={isBottomMenuEnabled} setIsBottomMenuEnabled={(isBottomMenuEnabled: boolean) => setIsBottomMenuEnabled(set('isBottomMenuEnabled', isBottomMenuEnabled))}/>
              <IonTabs id="main">

                {<IonRouterOutlet animated={animated}>
                  {/*Route MUST be direct child of IonRouterOutlet or weird animations happen!!!*/}
                  {/*Using the render method prop cuts down the number of renders your components will have due to route changes.*/}
                  {/*Use the component prop when your component depends on the RouterComponentProps passed in automatically.*/}
                  <Redirect exact path="/tabs" to="/tabs/map"/>
                  <Route path="/account" component={Account}/>
                  <Route path="/login" component={Login}/>
                  <Route path="/signup" component={Signup}/>
                  <Route path="/support" component={Support}/>
                  <Route path="/tutorial" component={Tutorial}/>
                  <Route path="/tabs/schedule" render={() => <SchedulePage/>} exact={true}/>
                  <Route path="/tabs/speakers" render={() => <SpeakerList/>} exact={true}/>
                  <Route path="/tabs/speakers/:id" component={SpeakerDetail} exact={true}/>
                  <Route path="/tabs/schedule/:id" component={SessionDetail}/>
                  <Route path="/tabs/speakers/sessions/:id" component={SessionDetail}/>
                  <Route path="/tabs/map" render={() => <MapView/>} exact={true}/>
                  <Route path='/tabs/directions-details' component={DirectionsDetails}/>
                  <Route path='/tabs/directions' component={DirectionsList}/>
                  <Route path="/tabs/about" render={() => <About/>} exact={true}/>
                  <Route path="/logout" render={() => {
                    setIsLoggedIn(false);
                    setUsername(undefined);
                    return <Redirect to="/tabs"/>
                  }}/>
                  <Route path="/" component={HomeOrTutorial} exact={true}/>
                </IonRouterOutlet>}
                <IonTabBar slot="bottom" hidden={isPlatform('desktop') || !(menuEnabled && isBottomMenuEnabled)}>
                  <IonTabButton tab="map" href="/tabs/map">
                    <IonIcon icon={mapSharp}/>
                    <IonLabel>Map</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="directions" href="/tabs/directions">
                    <IonIcon icon={compassOutline}/>
                    <IonLabel>Directions</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="schedule" href="/tabs/schedule">
                    <IonIcon icon={timeOutline}/>
                    <IonLabel>Stops</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="bug" onIonTabButtonClick={handleBugClick}>
                    <IonIcon icon={bugOutline}/>
                    <IonLabel>Report Bug</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </SetAnimatedContext.Provider>
    )
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  component: IonicApp,
  mapDispatchToProps: {loadConfData, loadUserData, setIsLoggedIn, setUsername},
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    menuEnabled: state.data.menuEnabled,
    schedule: state.data.schedule,
  }),
});
