import {Plugins} from '@capacitor/core';
import {isPlatform} from '@ionic/react';

import {get, init, set} from './storage';

const {Geolocation} = Plugins;

init('geolocatePermission', false);

// hardcoded location for surya to use in demo videos
const IP = '103.107.55.18';
const POSITION = {coords: {accuracy: 1, latitude: -33.8568, longitude: 151.2153}, timestamp: Date.now()};
let ip;
(async () => {
  const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
  const text = await response.text();
  const ipRegex = /ip=(.*)/;
  const arr = ipRegex.exec(text);
  ip = arr[1];
})();

// capacitor throws a Error with a message.  message varies by OS
// html5 includes an error code
export function isPermissionDenied(error) {
  return error.message === 'User denied location permission' //Android
  || error.message === 'User denied Geolocation' //ios 11 safari
  || error.message.includes('kCLErrorDomain error 1') //ios app
  || error.code === window.GeolocationPositionError?.PERMISSION_DENIED; //web
}

/**
 * @param {GeolocationPositionError|Error} error
 */
export function onGeolocateError(error) {
  if (isPermissionDenied(error)) {
    set('geolocatePermission', false);
  }
}

/**
 * @param {GeolocationPosition} data
 */
export function onGeolocateSuccess(data) {
  set('geolocatePermission', true);
}

export function hasGeolocatePermission() {
  return get('geolocatePermission');
}

/** @return {LocationOrError} */
export async function getGeolocation() {
  try {
    let position = await Geolocation.getCurrentPosition();
    if (ip === IP) {
      position = POSITION;
    }
    onGeolocateSuccess(position);
    return {location : [position.coords.longitude, position.coords.latitude]};
  } catch (error) {
    onGeolocateError(error);
    // can't throw because facebook's regenerator runtime won't let you .catch() it
    return {error : error};
  }
}

// mapboxgl.GeolocateControl uses these, but they don't work on capacitor ios
if (isPlatform('ios') && !isPlatform('mobileweb')) {
  window.navigator.geolocation.watchPosition = (successCallback, errorCallback, options) => {
    try {
      if (ip === IP) {
        successCallback(POSITION);
        return 0;
      }
      return Geolocation.watchPosition(options, successCallback);
    } catch (error) {
      errorCallback(error);
      return -1;
    }
  };

  window.navigator.geolocation.clearWatch = id => Geolocation.clearWatch({id});

  window.navigator.geolocation.getCurrentPosition = async (successCallback, errorCallback, options) => {
    try {
      let position = await Geolocation.getCurrentPosition(options);
      if (ip === IP) {
        position = POSITION;
      }
      successCallback(position);
    } catch (error) {
      errorCallback(error);
    }
  }
}
