
/*

This service is proxy service for TripGo transport service in this application for Scotland.
This file will only be loaded for Scotland app.
It works as below:
1. This service needs to be instantiated with other service's implementation [For example TripGo transport service]
2. This service will proxy the other service that was injected at the time of initialization
3. It will augment two API responses getRouting and getDeparture for bus and rail
   Regarding Ferry stops, it is taken care with rail stops. Analysed ferry stops online and fetched data for a trip from National Rail, Stromeferry, Strome Ferry, Scotland IV53 8UH, United Kingdom to National War Museum of Scotland, Edinburgh Castle, Edinburgh, Scotland EH1 2JR, United Kingdom
   It is reasonably long trip and includes many stops, analysed below ferry stops, they all are marked as pt_pub_train and mapping of wheelchair accessibility is available for them in rails.json file we have created
   'KYLELSH', 'DUIRNSH', 'PLOCKTN', 'DUNCRAG', 'STRMFRY', 'ATADALE', 'STCN', 'ACHHSHL'
   No need to make any modification as ferry stops are covered with rail stops.
4. For getRouting, there are stops and services too so we need to augment both for wheelchair accessibility
5. Steps
   a. Create promise functions to read bus/rail stops and routes mapping of wheelchair accessibility
   b. Created two functions augmentStopData and augmentSegmentData
   c. augmentStopData to augment bus and rail stops in the response
   d. This function has few inline functions to iterate and check if stopCode is known to use in the wheelchair accessibility information then augment json structure with wheelchairAccessible data
   e. augmentStopData is used in getRouting to iterate hierarchy and check if match is found then augment. This is done on top of response received from source service that was used to create this proxy service
   f. Then we have augmentSegmentData function, for the purpose as name suggests
   g. This function also has inline function checkWheelchairAccessibleByCodeAndType which is used extensively in the same function to check object to augment at different level
   h. Above mentioned function will augment data based on bus/rai stop codes.
   i. Observed that there are no stop codes for service object inside embarkationStops
   j. So for such segments where we don't have stop code, we are trying to lookup against operator code and service number

Route id is known with different names like MRNDReference and TNDS-S, route_id
Operator ID is known with different names in different files like agency_id, operator code, .
Service number is known with different names in different files like route_short_name, service number
Stop ID is known with different names in different files like stop_id, crs, AtcoCode

Additional findings
TripGo uses (XML Files) national operator code for services to get wheelchair accessibility mapping which was found S.zip.
  And these agency id / operator code from TripGo API response are matching with the agency_id in UK Accessibility data file IF145.

  Below is the sample XML where FGL is national operator code and FGLA is
  <Operators>
    <Operator id="FGL">
      <NationalOperatorCode>FGLA</NationalOperatorCode>
      <OperatorCode>FGL</OperatorCode>
      <OperatorShortName>First Glasgow</OperatorShortName>
    </Operator>
  </Operators>


  Test cases for this service are written in UKWheelchairProxy.test.js file

*/

import {ATransportService} from 'services/ATransportService'
import busStopCodesIsAccessible from 'data/scotland/wheelchair/stops/bus'
import railStopCodesIsAccessible from 'data/scotland/wheelchair/stops/rail'
import busRouteIdIsAccessible from 'data/scotland/wheelchair/routes/bus_route_id_map'
import busOperatorCodeAndServiceNumIsAccessible from 'data/scotland/wheelchair/routes/bus_opcode_servicenum_map'

import partialStationAccessibilityNotesForRail from 'data/scotland/wheelchair/stops/partial-accessibility-notes.json'
import {WheelchairAccessibilityEnum} from './WheelchairAccessibilityEnum'

const KEY_WHEELCHAIR_ACCESSIBLE = 'wheelchairAccessible'

const augmentStopData = async function (responseJSON) {

  /*
    make sure wheelchairAccessible property is not set for a stop being checked.
    access bus.json data / map from store and check if respective shop code has data available for it?
    then update stop with wheelchairAccessible
  */
  const setBusStopWheelchairAccessibility = stop => (!(KEY_WHEELCHAIR_ACCESSIBLE in stop)) && (stop.code in busStopCodesIsAccessible) && (stop.wheelchairAccessibility = busStopCodesIsAccessible[stop.code])
  const setBusShapeWheelchairAccessibility = shape => shape.stops?.forEach(setBusStopWheelchairAccessibility)

  const setRailStopWheelchairAccessibility = stop => (!(KEY_WHEELCHAIR_ACCESSIBLE in stop)) && (stop.code in railStopCodesIsAccessible) && (stop.wheelchairAccessibility = railStopCodesIsAccessible[stop.code])
  const setRailShapeWheelchairAccessibility = shape => shape.stops?.forEach(setRailStopWheelchairAccessibility)

  const setBusSegmentTemplateWheelchairAccessibility = segmentTemplate => {
    // Check if it is a bus stop
    if (segmentTemplate.isBus) {
      segmentTemplate.shapes?.forEach(setBusShapeWheelchairAccessibility)
    }
    // or if it is a rail stop
    else if (segmentTemplate.isRail) {
      segmentTemplate.shapes?.forEach(setRailShapeWheelchairAccessibility)
    }
  }
  // check if segment is public transport segment
  const setSegmentTemplateWheelchairAccessibility = segmentTemplate => segmentTemplate.isPublicTransit && setBusSegmentTemplateWheelchairAccessibility(segmentTemplate)

  const isPublicTransit = (segment) => {
    return segment.template.modeInfo.identifier.startsWith("pt_");
  };

  // process each segment template
  responseJSON?.segmentTemplates?.forEach(setSegmentTemplateWheelchairAccessibility)
  
  responseJSON?.groups?.forEach(group => {
    if (group.trips?.length > 0) {
      const trip = group.trips[0];  // pick first trip as this data is sorted by weighted score in TripGoTransportServiceImpl

      trip.segments?.filter(isPublicTransit).forEach(segment => {
        const stopss = segment.template.shapes?.filter(shape => shape.travelled).map(shape => shape.stops)

        stopss.forEach(stops => {
          stops.forEach(stop => {
            if(stop.code in partialStationAccessibilityNotesForRail){
              stop.note = partialStationAccessibilityNotesForRail[stop.code];
              if(!!stop.note){
                stop.wheelchairAccessibility =  WheelchairAccessibilityEnum.PARTIAL;
              }
            }
          })
        })
      });
    };
  });
}

const augmentSegmentData = async function (responseJSON) {
  const RAIL_REPLACEMENT_STRING = 'replacement bus';
  // process each segment template
  responseJSON?.groups?.forEach(group => {
    group.trips?.forEach(trip => {
      trip.segments?.forEach(segment => {
        if (!(KEY_WHEELCHAIR_ACCESSIBLE in segment)) {
          if (segment.routeId in busRouteIdIsAccessible) {
            segment.vehicleWheelchairAccessibility = busRouteIdIsAccessible[segment.routeId];
          } else if (segment.template.operatorID in busOperatorCodeAndServiceNumIsAccessible && segment.serviceNumber in busOperatorCodeAndServiceNumIsAccessible[segment.template.operatorID]) {
            segment.vehicleWheelchairAccessibility = busOperatorCodeAndServiceNumIsAccessible[segment.template.operatorID][segment.serviceNumber];
          } else if (segment.serviceName?.includes(RAIL_REPLACEMENT_STRING)) {
            // check if service name contains [replacement bus] then mark as wheelchair not accessible
            // This we are marking based on findings in railreplacementbus ZZALL FALSE in UK_IF145_ModeAccessibility File
            segment.vehicleWheelchairAccessibility = WheelchairAccessibilityEnum.INACCESSIBILE;
          } else if (segment.template.isRail) {
            // For rail segment, wheelchair is always accessible.
            segment.vehicleWheelchairAccessibility = WheelchairAccessibilityEnum.ACCESSIBLE;
          }
        }
      }); // SEGMENT
    }); // TRIP
  }); // GROUP
}

const augmentDepartureData = async function (responseJSON) {

  /*
    Inline function to check if input object has a type either bus or train and no wheelchairAccessibility information available
    Then it does a lookup against bus/rail stop code to see if wheelchair accessibility info is available within a map created using UK/Scotland wheelchair accessibility data.
  */
  const checkWheelchairAccessibleByCodeAndType = object => {
    let code = object.code || object.stopCode;
    let type = object.stopType || object.type;
    type === 'bus' && (!(KEY_WHEELCHAIR_ACCESSIBLE in object)) && (code in busStopCodesIsAccessible) && (object.wheelchairAccessibility = busStopCodesIsAccessible[code]);
    type === 'train' && (!(KEY_WHEELCHAIR_ACCESSIBLE in object)) && (code in railStopCodesIsAccessible) && (object.wheelchairAccessibility = railStopCodesIsAccessible[code]);

    
    if(code in partialStationAccessibilityNotesForRail) {
      object.note = partialStationAccessibilityNotesForRail[code];
      if(!!object.note) {
        object.wheelchairAccessibility = WheelchairAccessibilityEnum.PARTIAL;
      }
    }
  }

  /*
    Augmenting departure data for response.stops and embarkationStops
    this is used to show data on Schedule page
  */
  responseJSON?.stops?.forEach(stop => {
    checkWheelchairAccessibleByCodeAndType(stop);
    stop.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  });

  responseJSON?.embarkationStops?.forEach(embarkationStop => {
    embarkationStop.services?.forEach(service => {
      // If it is train then wheelchair is accessible for all trains
      service.mode === 'train' && (!(KEY_WHEELCHAIR_ACCESSIBLE in service)) && (service.wheelchairAccessibility = WheelchairAccessibilityEnum.ACCESSIBLE);
      // For all non train modes, check of there is a match found for give operator id and service number.
      service.mode !== 'train' && (!(KEY_WHEELCHAIR_ACCESSIBLE in service)) && (service.operatorID in busOperatorCodeAndServiceNumIsAccessible) && (service.serviceNumber in busOperatorCodeAndServiceNumIsAccessible[service.operatorID]) && (service.wheelchairAccessibility = busOperatorCodeAndServiceNumIsAccessible[service.operatorID][service.serviceNumber]);
    });

    if(embarkationStop.code in partialStationAccessibilityNotesForRail){
      embarkationStop.note = partialStationAccessibilityNotesForRail[embarkationStop.code]
      if(!!embarkationStop.note) {
        embarkationStop.wheelchairAccessibility = WheelchairAccessibilityEnum.PARTIAL;
      }
    }
  });

  /*
    Augmenting parentInfo based on its stop type and stop code.
    parentInfo is not available for all departures, it was seen while searching for "glasgow central station" in UK_SouthScotland region
    This piece of code looks up at parentInfo as well as at its children and for both tries to augment it in the exact same way
  */
  if (responseJSON?.parentInfo) {
    let parentInfo = responseJSON.parentInfo;
    checkWheelchairAccessibleByCodeAndType(parentInfo);
    parentInfo.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  }

  /*
    Augmenting parentStops based on its stop type and stop code.
    parentStops is not available for all departures, it was seen while searching for "glasgow central station" in UK_SouthScotland region
    This piece of code looks up at parentStop as well as at its children and for both tries to augment it in the exact same way
  */
  responseJSON?.parentStops?.forEach(parentStop => {
    checkWheelchairAccessibleByCodeAndType(parentStop);
    parentStop.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  });

  // All above code is tested by setting region as UK_SouthScotland in config and by searching "glasgow central station" in the /tabs/schedule page,
  // It is able to augment wheelchairAccessibility based on bus.json and rail.json data available for Scotland.

}

export class UKWheelchairProxy extends ATransportService {

  // Internally call getRouting of the service being proxied and augment response before sending
  getRouting = async (fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops = []) => {
    const responseJSON = await this.transportService.getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops);
    await augmentStopData(responseJSON);
    await augmentSegmentData(responseJSON);
    return responseJSON;
  }

  // getLocations is not overridden in this class so getLocations of parent Abstract class will be used


  // Internally call getDeparture of the service being proxied and augment response before sending
  getDeparture = async (embarkationStops, favourites) => {
    const responseJSON = await this.transportService.getDeparture(embarkationStops, favourites);
    await augmentDepartureData(responseJSON);
    return responseJSON;
  }

}

/*

  Notes for modeInfo identifier pt_pub_tram.
  While searching for "glasgow central station" in UK_SouthScotland region,
  Observed new modeInfo.identified pt_pub_tram in the response of departure.json API from TripGo.

  On further analysis with respect to Scotland data, we found that only two trams are accessible: LDLR and TRAM - probably both are not in scotland
  So later as we will develop app for all UK, we will have to consider tram and augment data accordingly.

*/
