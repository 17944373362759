/**
 * @param {function(): void} handler
 * @param {number} timeout
 */
export default function debounce(handler, timeout) {
  let handle;
  return function () {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(() => handler.call(this, arguments), timeout);
  };
};
