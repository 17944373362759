import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonCard,
  IonCardContent,
  IonToolbar,
  getConfig,
} from '@ionic/react';
import {flagOutline, flagSharp} from 'ionicons/icons';
import {toGeoJSON} from '@mapbox/polyline';
import {useLocation} from 'react-router';

import IonPage from './JynPage';
import JynBackButton from '../components/JynBackButton';
import Map from '../components/Map';
import debounce from '../util/debounce';
import mapboxgl from '../util/mapbox-gl';
import {ResultGroup} from '../components/ResultGroup';
import {Segments} from '../components/Segments';
import {getIcon} from '../components/Icon';
import {getStringFromSeconds, hourMinuteSecondFormat} from '../util/time';
import {sineScroll} from '../util/scroll';
import {WheelChairAccessibilityIcon, WheelChairAccessibilityBanner} from '../components/WheelChairAccessibility'

import './DirectionsDetails.scss';
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {WheelchairAccessibilityEnum} from 'services/impl/WheelchairAccessibilityEnum';

const INSTRUCTIONS = {
  CONTINUE_STRAIGHT: 'Continue straight', //Continue forward (+/- 20 degrees)
  HEAD_TOWARDS: 'Head towards', //Start the segment heading over the street
  TURN_LEFT: 'Turn left', //Turn left between 60 and 115 degrees
  TURN_RIGHT: 'Turn right', //Turn right between 60 and 115 degrees
  TURN_SHARPLY_LEFT: 'Turn sharply left', //Turn left more than 115 degrees
  TURN_SHARPLY_RIGHT: 'Turn sharply right', //Turn right more than 115 degrees
  TURN_SLIGHTLY_LEFT: 'Turn slightly left', //Turn left between 20 and 60 degrees
  TURN_SLIGHTLY_RIGHT: 'Turn slightly right', //Turn right between 20 and 60 degrees
  UNKNOWN: '', //No turn instruction available
};

const UA = navigator.userAgent;
const isNotPropagatingPointerEvents = /\b(AppleWebKit|Chrome)\/\b/.test(UA);

const EXPAND_BY_DEFAULT_THRESHOLD = 2;
const COLLAPSED_DOTS= Array.from({length: 5}, (_, i) => <div className="dot" id={i} key={i}/>);

const Segment = ({segment, s, segments}) => {
  const segmentDetail = React.useRef(null)
  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();
  // for detail open/close status
  const [areDetilsOpen, setAreDetailsOpen] = React.useState(false)

  if (segment.realtimeStops) {
    segment.stopPredictions = {};
    segment.realtimeStops.forEach(stop => segment.stopPredictions[stop.code] = stop);
  }

  const isExpandable = segment.template.streets?.length > 1 || segment.template.shapes?.filter(shape => shape.travelled).map(shape => shape.stops).flat().length > EXPAND_BY_DEFAULT_THRESHOLD;
  const Expandable = isExpandable ? 'details' : 'div';

  const getSegmentSummary = (segment) => {
    const wheelchairIcon = shouldShowWheelchairAccessibility && <WheelChairAccessibilityIcon wheelchairAccessibility={segment.vehicleWheelchairAccessibility}/>

    if (segment.serviceNumber) {
      return <span>{segment.serviceNumber}{wheelchairIcon}</span>
    } else if (segment.stops) {
      return `${segment.stops} stops`;
    } else {
      return "Directions";
    }
  };

  const isPublicTransit = () => {
    return segment.template.modeInfo.identifier.startsWith("pt_");
  };

  const renderStops = (stop, s, stops) => {
    const departure = segment.stopPredictions?.[stop.code].predictedDeparture ??
      segment.startTime + stop.relativeDeparture;
    const arrival = segment.stopPredictions?.[stop.code].predictedArrival ??
      segment.startTime + stop.relativeArrival;
    const date = new Date(1000 * (s === stops.length - 1 ? arrival : departure));

    return (
      <div className="stops-container">
        { s === stops.length -1 &&
          (<div className="dots" onClick={() => setAreDetailsOpen(!areDetilsOpen)}>
            {COLLAPSED_DOTS}
          </div>)
        }
        <div className="stops">
          {shouldShowWheelchairAccessibility && <WheelChairAccessibilityIcon wheelchairAccessibility={stop.wheelchairAccessibility}/>}
          {hourMinuteSecondFormat.format(date)}
          <span className="stop-name"> &nbsp;-&nbsp;{stop.name}</span>
        </div>

        {stop.wheelchairAccessibility ===  WheelchairAccessibilityEnum.PARTIAL &&
          (<div className="note-section">
            <span className="partial-accessible-note">Note: {stop.note}</span>
          </div>
          )}
      </div>
    )
  }

  return <IonItem key={s} className="segment-container">
    <IonLabel className='segment-main'>
      <div className='segment-summary'>
        {/* segment icon */}
        <div className="segment-icon">
          {getIcon(segment?.template?.modeInfo)}
        </div>
        {/* other segment related summary */}
        <div className="segment-info">
          <div className="time-info">
            <div className="info-left">
              {hourMinuteSecondFormat.format(new Date(1000 * segment?.startTime))}<br/>
            </div>
            <div className="info-right">
              {isPublicTransit() && shouldShowWheelchairAccessibility? <WheelChairAccessibilityBanner wheelchairAccessibility={segment.wheelchairAccessibility}/> : (
                <span>
                  <span className="duration">{getStringFromSeconds(segment?.endTime - segment?.startTime)}</span>&ensp;
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isPublicTransit() && (
        <div className="stop-info">
          <p className="segment-title">{segment?.template?.from?.address ?? segment?.template?.mini?.instruction}</p>
        </div>
      )}

      <div className="details-container">
        <Expandable ref={segmentDetail} className='segment-details' open={areDetilsOpen}>
          <summary className="summary-arrow">
            {isExpandable && getSegmentSummary(segment)}
          </summary>
          <table>
            <tbody>{segment.template.streets?.length > 1 && segment.template.streets.map((street, s) =>
              // TODO: dismount=false > cycle
              // TODO: dismount=true > walk
              <tr key={s}>
                <td>{INSTRUCTIONS[street?.instruction]}</td>
                <td>{street?.name}</td>
              </tr>,
            )}</tbody>
          </table>
        </Expandable>
        {isPublicTransit() && (
          <div className="summary-right">
            <span className="duration">{getStringFromSeconds(segment?.endTime - segment?.startTime)}</span>&ensp;
            <span className="totalStops">{segment.stops} stops</span>
          </div>
        )}
        {isPublicTransit() && (
          <div className="stop-info">
            <p className="segment-title">{segment?.template?.from?.address ?? segment?.template?.mini?.instruction}</p>
          </div>
        )}
        <div className="stop-list">
          {segment.template.shapes?.filter(shape => shape.travelled)
            .map(shape => shape.stops.map(renderStops))}
        </div>
      </div>
    </IonLabel>
  </IonItem>;
}

export default function DirectionsDetails() {

  const location = useLocation();
  const ios = getConfig().get('mode') === 'ios';
  const state = location.state;

  const mapBackgroundRef = React.useRef();
  const scrollableRef = React.useRef();
  const [trip, setTrip] = React.useState(state?.trip);
  const [directions, setDirections] = React.useState();
  const [bounds, setBounds] = React.useState();
  const [halfScreenMap, setHalfScreenMap] = React.useState(false);
  // Ref for summary height management
  const summaryRef = React.useRef();

  React.useEffect(() => {
    tryScroll();

    function tryScroll() {
      /** @type HTMLDivElement */
      const mapBackgroundElement = mapBackgroundRef.current;
      /** @type HTMLDivElement */
      const scrollableElement = scrollableRef.current;
      if (mapBackgroundElement && scrollableElement) {
        const height = mapBackgroundElement.getBoundingClientRect().height;
        if (height !== 0) {
          sineScroll(scrollableElement, 'scrollTop', height / 2);
          setHalfScreenMap(true);

          // TODO: move to onScroll={debounce(handler, 200)}
          const handler = () => {
            const scrollTop = scrollableElement.scrollTop;
            const height = mapBackgroundElement.getBoundingClientRect().height;
            if (scrollTop < height / 4) {
              sineScroll(scrollableElement, 'scrollTop', 0);
              scrollableElement.classList.add('full-map');
              setHalfScreenMap(false);
            } else {
              if (scrollTop < height * 3 / 4) {
                sineScroll(scrollableElement, 'scrollTop', height / 2);
                setHalfScreenMap(true);
              } else if (scrollTop < height) {
                sineScroll(scrollableElement, 'scrollTop', height);
              }
              scrollableElement.classList.remove('full-map');
            }
          };
          scrollableElement.addEventListener('scroll', debounce(handler, 200));
          return;
        }
      }
      setTimeout(tryScroll, 100);
    }
  }, []);

  React.useEffect(() => {
    if (state?.trip) {
      const trip = state.trip;
      setTrip(trip);
      const directions = {
        "type": "FeatureCollection",
        // eslint-disable-next-line sort-keys
        "features": trip.segments.filter(segment => {
          // template could be stationary segment
          const template = segment.template;
          return (template.streets || template.shapes) && template.modeInfo?.color;
        }).flatMap(segment => {
          const template = segment.template;
          const ss = template.streets || template.shapes;
          const color = segment.serviceColor ?? template.modeInfo.color;
          const rgb = `#` +
            color.red.toString(16).padStart(2, '0') +
            color.green.toString(16).padStart(2, '0') +
            color.blue.toString(16).padStart(2, '0');
          return ss.map(s => ({
            type: 'Feature',
            // eslint-disable-next-line sort-keys
            geometry: toGeoJSON(s.encodedWaypoints),
            properties: {opacity: template.streets || s.travelled ? 1 : 0.2, rgb: rgb},
          }));
        }),
      };

      const coordinates = directions.features.filter(feature => feature.properties.opacity === 1.0)
        .flatMap(feature => feature.geometry.coordinates);
      const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord),
                                        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      setDirections(directions);
      setBounds(bounds.toArray());
    }
  }, [state?.trip])

  const onCoverClick = () => {
    /** @type HTMLDivElement */
    const scrollableElement = scrollableRef.current;
    sineScroll(scrollableElement, 'scrollTop', 0);
    scrollableElement.classList.add('full-map');
    setHalfScreenMap(false);
  };
  const onSummaryClick = () => {
    /** @type HTMLDivElement */
    const mapBackgroundElement = mapBackgroundRef.current;
    /** @type HTMLDivElement */
    const scrollableElement = scrollableRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const height = mapBackgroundElement.getBoundingClientRect().height;
    if (scrollTop < height * 3 / 4) {
      sineScroll(scrollableElement, 'scrollTop', height);
      scrollableElement.classList.remove('full-map');
    } else {
      sineScroll(scrollableElement, 'scrollTop', 0);
      scrollableElement.classList.add('full-map');
      setHalfScreenMap(false);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // chrome doesn't propagate pointer events up to the scrollable parent, so we scroll manually
  let clientY;
  const onTouchStart = e => clientY = e.touches[0].clientY;
  const onTouchMove = e => {
    /** @type HTMLDivElement */
    const scrollable = scrollableRef.current;
    if (isNotPropagatingPointerEvents && scrollable.classList.contains('full-map')) {
      const newClientY = e.changedTouches[0].clientY;
      const deltaY = newClientY - clientY;
      clientY = newClientY;
      scrollable.scrollTop -= deltaY;
    }
  };
  const onWheel = e => {
    /** @type HTMLDivElement */
    const scrollable = scrollableRef.current;
    if (isNotPropagatingPointerEvents && scrollable.classList.contains('full-map')) {
      scrollable.scrollTop += e.deltaY;
    }
  };

  // TODO: read ref after mounting and setSummaryHeight as state variable to trigger re-render
  // we could use CSS to set the styles, but there are too many possible values so we just detect the height needed for the style
  const summaryHeight = summaryRef.current?.offsetHeight ?? 120;

  // chrome doesn't propagate pointer events up to the scrollable parent, so we scroll manually
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <IonPage title="Directions Details" canResume={false}>
      {ios && <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <JynBackButton defaultHref='/tabs/map'/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>}
      <IonContent>
        <div className='map-background' ref={mapBackgroundRef} slot='fixed' style={{bottom: summaryHeight}}>
          <Map bounds={bounds} directions={directions} zoomOutTop={halfScreenMap} usesRoads={trip?.usesRoads} usesActiveTransport={trip?.usesActiveTransport}/>
        </div>
        <div className='outer-scrollable' ref={scrollableRef} slot='fixed'>
          <div onClick={onCoverClick} style={{height: `calc(100% - ${summaryHeight}px)`}}/>
          {!ios && <JynBackButton defaultHref='/tabs/map' slot='fixed'/>}
          {trip ?
            <IonList className='directions-details'
                     onTouchStart={onTouchStart}
                     onTouchMove={onTouchMove}
                     onWheel={onWheel}>
              <div className='directions-details-summary' ref={summaryRef} onClick={onSummaryClick}>
                <ResultGroup trip={trip}>
                  <Segments trip={trip}/>
                </ResultGroup>
              </div>
              {trip?.segments?.map((segment, s, segments) => <Segment segment={segment} s={s} segments={segments}/>)}
              <IonItem className="segment-container">
                <IonLabel className='segment-main'>
                  <div className='segment-summary'>
                    <div className="segment-icon">
                      <IonIcon className="mode-icon" ios={flagOutline} md={flagSharp}/>
                    </div>
                    <div className="segment-info">
                      <div className="time-info">
                        <div className="info-left">
                          {hourMinuteSecondFormat.format(new Date(1000 * (trip?.arrive ?? 0)))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-container">
                    <div className='segment-details'>
                        {trip.segments[trip.segments.length - 1]?.template.to?.address}
                    </div>
                  </div>
                </IonLabel>
              </IonItem>
            </IonList>
            :
            <IonCard className='directions-details'>
              <IonCardContent className='directions-details-summary' ref={summaryRef}>
                No trip was found.  Please go back.
              </IonCardContent>
            </IonCard>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};
