import React from 'react';
import {
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/react';
import moment from 'moment-timezone';
import {heart, heartOutline, pulse, remove} from 'ionicons/icons';
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js';

import * as favourites from "../util/favourites";
import {hourMinuteSecondFormat} from '../util/time';
import {WheelChairAccessibilityIcon, WheelChairAccessibilityBanner} from './WheelChairAccessibility'

import './StopList.scss';
import {timeZone} from 'data/apps/config';
import {getIcon} from './Icon';
import {
  sendEvent,
  GA_CATEGORY_UNKNOWN_ACCESSIBILITY,
  GA_ACTION_STOP,
  GA_CATEGORY_WHEELCHAIR_INACCESSIBLE,
} from 'components/Analytics'
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {WheelchairAccessibilityEnum} from 'services/impl/WheelchairAccessibilityEnum';
// departures contains embarkationStops and stops
// embarkationStops contains services and stopCode
// stops contain code, name, region, stopCode, and maybe children (which contain the same field: code, name, region, and stopCode)
// here we create a map recursively from stop.stopCode to stop, so that we can filter embarkationStops with embarkationStop.stopCode
const getStopCodesStop = function (stops) {
  const stopCodesStop = {};
  const addStopCodeStop = stop => {
    stopCodesStop[stop.stopCode] = stop;
    // eslint-disable-next-line no-unused-expressions
    stop.children?.forEach(addStopCodeStop);
  };
  stops.forEach(addStopCodeStop);
  return stopCodesStop;
};

const getNumberDirectionsServices = function (services) {
  const numberDirectionsServices = {};
  services.forEach(service => {
    const numberDirection = JSON.stringify([service.serviceNumber,
      service.serviceDirection]);
    numberDirectionsServices[numberDirection] = numberDirectionsServices[numberDirection] || [];
    numberDirectionsServices[numberDirection].push(service);
  });
  return numberDirectionsServices;
};

/**
 * @param departures                  departures in TripGo departures.json format
 * @param {StopListFilterType} filter modes and radius filter
 * @param geolocation                 location to calculate distance of each stop, for sorting stops
 * @param searchText                  filter services with this text, or stops with
 *                                    this text, or stops with services with this text
 * @param visibleRegionsStopCodes     if not null, which stops should be visible (e.g. favourites).  if null, all stops.
 * @returns {JSX.Element}
 */
export default function StopList({departures, filter, geolocation, searchText, visibleRegionsStopCodes}) {
  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();
  const embarkationStops = departures?.embarkationStops;
  if (
    embarkationStops?.length) {
    // const geolocationLatLon       = geolocation ? new LatLon(geolocation[1], geolocation[0]):null;
    const stopCodesStop = getStopCodesStop(departures.stops);
    const foundTextEmbarkationStops = embarkationStops.filter(embarkationStop =>
      // on nearest tab, keep all stops
      visibleRegionsStopCodes === null ||
      // on favourites tab, only keep favourites
      visibleRegionsStopCodes[stopCodesStop[embarkationStop.stopCode].region]?.includes(stopCodesStop[embarkationStop.stopCode].code),
    ).filter(embarkationStop => {
      const numberDirectionsServices = getNumberDirectionsServices(embarkationStop.services);
      let isNumberDirections = false;
      Object.entries(numberDirectionsServices).forEach(([numberDirection]) => {
        const [number, direction] = JSON.parse(numberDirection);
        if (number?.toLowerCase().includes(searchText.toLowerCase() ||
          direction?.toLowerCase().includes(searchText.toLowerCase()))) {
          isNumberDirections = true
        }
      })

      if (stopCodesStop[embarkationStop.stopCode].name.toLowerCase().includes(searchText.toLowerCase()) || isNumberDirections) {
        embarkationStop.visibleServices = embarkationStop.services;
      } else {
        embarkationStop.visibleServices =
          embarkationStop.services.filter(service =>
            service.searchString.toLowerCase().includes(searchText.toLowerCase()));
      }
      embarkationStop.visibleServices =
        embarkationStop.visibleServices.filter(service => filter.modes.includes(service.mode));
      return embarkationStop.visibleServices.length;
    });

    const visibleEmbarkationStops = (geolocation ?
      foundTextEmbarkationStops.filter(embarkationStop => {
        const geolocationLatLon = new LatLon(geolocation[1], geolocation[0]);
        const stopCode = embarkationStop.stopCode;
        const stop = stopCodesStop[stopCode];
        const stopLatLon = new LatLon(stop.lat, stop.lng);
        embarkationStop.metres = stopLatLon.distanceTo(geolocationLatLon);
        return embarkationStop.metres <= filter.radius || favourites.includes(stop.region, stop.code);
      }) : foundTextEmbarkationStops).filter(embarkationStop =>
      // known to be accessible -> wheelchairAccessible === true
      // not known to be inaccessible -> wheelchairAccessible !== false
      // show all stops -> true
      filter.wheelChairAccessibility === 'accessible-only' ? stopCodesStop[embarkationStop.stopCode].wheelchairAccessibility : filter.wheelChairAccessibility === 'not-inaccessible' ? stopCodesStop[embarkationStop.stopCode].wheelchairAccessibility !== false : true,
    );

    if (visibleEmbarkationStops.length) {
      visibleEmbarkationStops.sort((a, b) => a.metres - b.metres);

      return <IonList>
      {visibleEmbarkationStops.map(embarkationStop =>
        <Stop embarkationStop={embarkationStop} key={embarkationStop.stopCode}/>,
      )}
    </IonList>;

      function Stop({embarkationStop}) {
        const stopCode = embarkationStop.stopCode;
        const stop = stopCodesStop[stopCode];
        const numberDirectionsServices = getNumberDirectionsServices(embarkationStop.visibleServices);

        const [isFavourite, setIsFavourite] = React.useState(favourites.includes(stop.region, stop.code));

        const toggleFavourite = () => {
          setIsFavourite(isFavourite ?
            favourites.remove(stop.region, stop.code) :
            favourites.add(stop.region, stop.code));
            
          // log wheelchair accessibility unknown or inaccessible at Google Analytics when user adds a new favourite stop
          if(!isFavourite) {
            if (stop.wheelchairAccessibility === undefined) {
              sendEvent(GA_CATEGORY_UNKNOWN_ACCESSIBILITY, GA_ACTION_STOP, stop.code);
            } else if (stop.wheelchairAccessibility === false) {
              sendEvent(GA_CATEGORY_WHEELCHAIR_INACCESSIBLE, GA_ACTION_STOP, stop.code);
            }
          }
        };
        
        return <IonItemGroup key={stopCode}>
          <IonItemDivider onClick={toggleFavourite} sticky className="sticky-divider">
            <div className="header-section">
              {getIcon(stop.modeInfo)}
              <div className="stopName">
                {stop.name}
                {geolocation ? ` — ${embarkationStop.metres.toFixed(0)}m away` : null}
              </div>
              {shouldShowWheelchairAccessibility && <WheelChairAccessibilityBanner  wheelchairAccessibility={stop.wheelchairAccessibility}/>}
              { (stop.wheelchairAccessibility === WheelchairAccessibilityEnum.PARTIAL) && <div className="note-section" style={{paddingLeft: 0}}>
                <span className="partial-accessible-note">Note: {stop.note}</span>
              </div>
              }
            </div>
            <IonIcon icon={isFavourite ? heart : heartOutline} slot='end'/>
          </IonItemDivider>

          {Object.entries(numberDirectionsServices).map(([numberDirection, services], routeIndex) => {
            const [number, direction] = JSON.parse(numberDirection);
            const style = services[0]?.serviceColor ?
              {
                borderLeft: `2px solid rgb(${services[0].serviceColor.red},${services[0].serviceColor.green},${services[0].serviceColor.blue})`,
                paddingLeft: '10px',
              } :
              {};

            if (filter.wheelChairAccessibility !== 'all') {
              services = services.filter(service => filter.wheelChairAccessibility === 'accessible-only' ? service.wheelchairAccessibility : service.wheelchairAccessibility !== false)
            }
            const routeCount = Object.entries(numberDirectionsServices).length;
            return (services.length > 0 &&
              <IonItemSliding key={numberDirection}>
                <IonItem lines={routeIndex === routeCount - 1 ? "none" : "inset"}>
                  <IonLabel style={style}>
                    <div className="service-header">
                      {getIcon(services[0].modeInfo)}
                      <h3>
                        {number} &mdash;&nbsp;
                      {direction}
                      </h3>
                    </div>
                    {services.map((service, serviceIndex) => {
                      const time = moment.unix(service.realTimeDeparture ?? service.startTime).tz(timeZone);
                      return (
                        <div key={serviceIndex} className="stops">
                          {shouldShowWheelchairAccessibility && <WheelChairAccessibilityIcon wheelchairAccessibility={service.wheelchairAccessibility}/>}
                          <p key={service.startTime}>
                            {hourMinuteSecondFormat.format(time.toDate())}&nbsp;
                          <IonIcon icon={service.realTimeStatus === "IS_REAL_TIME" ? pulse : remove}/>&nbsp;
                          {time.fromNow()}
                          </p>
                        </div>
                      )
                    })}
                  </IonLabel>
                </IonItem>
              </IonItemSliding>
            );
          })}
        </IonItemGroup>;
      }
    }
  }
  return (
    <IonList>
      <IonListHeader>
        {departures ? 'No Stops Found' : 'No Stops Requested'}
      </IonListHeader>
    </IonList>
  );
};
