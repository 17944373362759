import React from 'react';
import Map from '../components/Map.js';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { connect } from '../data/connect';

import IonPage from './JynPage';

interface OwnProps {
}

interface DispatchProps {
}

interface MapViewProps extends OwnProps, DispatchProps {
}

const MapView: React.FC<MapViewProps> = () => {

  return (
    <IonPage id='map-view' title='Map'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Map</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="map-page" forceOverscroll={false}>
        <Map />
      </IonContent>
    </IonPage>
  )
};

export default connect<OwnProps, DispatchProps>({
  component: MapView
});
