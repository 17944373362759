/*
  Test cases for this service are written in TripGoTransportServiceImpl.test.js file
*/
import {ATransportService} from 'services/ATransportService'
import {WheelchairAccessibilityEnum, getWheelchairAccessibility} from './WheelchairAccessibilityEnum';

const TRIPGO_API = `https://api.tripgo.com/v1`;
const TRIPGO_KEY = '44b87680b7a8a3448244aa0d04920def';
const TRIPGO_REQ_HEADERS = {
  'Content-type': 'application/json',
  'X-TripGo-Key': TRIPGO_KEY,
};

const PUB_TRANS_IDENTIFIER = 'pt_pub'
const PUB_TRANS_BUS_IDENTIFIER = 'pt_pub_bus'
const PUB_TRANS_RAIL_IDENTIFIER = 'pt_pub_train'

export class TripGoTransportServiceImpl extends ATransportService {

  // To map segmentTemplate with each group.trip.segment so we don't need to lookup segment template each time
  unhash({groups, segmentTemplates}) {
    groups?.forEach(group =>
      group.trips?.forEach(trip =>
        trip.segments.forEach(segment =>
          segment.template = segmentTemplates.filter(
            template => template.hashCode === segment.segmentTemplateHashCode,
          )[0],
        ),
      ),
    )
  }

  // Sort groups and trips and attach score and duration of best weighted group/trip.
  // These additional attributes attached here(score and duration) are later used in DirectionsList to sort modes and show best trips first in the list.
  sortByWeightedScoreAndAttachBestScoreAndDuration = (responseJSON) => {
    responseJSON?.groups?.forEach(group => {
      group.trips.sort((a, b) => a.weightedScore - b.weightedScore);
      group.score = group.trips[0].weightedScore;
      group.duration = group.trips[0].arrive - group.trips[0].depart;
    })
    responseJSON?.groups?.sort((a, b) => a.score - b.score);
    if(responseJSON?.groups?.length > 0){
      responseJSON.score = responseJSON.groups[0].score;
      responseJSON.duration = responseJSON.groups[0].duration;
    }
  };
  getWheelchairAccessibility = (object) => {
    return object?.wheelchairAccessible === true ? WheelchairAccessibilityEnum.ACCESSIBLE : object.wheelchairAccessible === false ? WheelchairAccessibilityEnum.INACCESSIBLE: WheelchairAccessibilityEnum.UNKNOWN
  }
  // Get routing details for given parameters from TripGo
  getRouting = async (fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops = []) => {
    const neverAllowStopsParam = neverAllowStops.reduce((prev, stop) => prev + "&neverAllowStops=" + stop, "");
    const url = `${TRIPGO_API}/routing.json?from=${fromParam}&${anchor}=${unix}&to=${toParam}&${modeParams}&v=11&ir=1&includeStops=true${avoidModesParam}${routeParam}${neverAllowStopsParam}`
    const response = await fetch(url, {
      headers: TRIPGO_REQ_HEADERS,
    })

    const responseJSON = await response.json()

    const setActiveModes = trip => {
      const modes = trip?.segments.map(segment => segment.template.modeInfo.identifier);
      // motor vehicle => usesRoads
      trip.usesRoads = modes.some(mode => mode.startsWith('me_') ||  mode.startsWith('ps_'));
      // cycling or walking-only => usesActiveTransport
      trip.usesActiveTransport = modes.some(mode => mode.startsWith('cy_')) ||
      modes.every(mode => mode.startsWith('wa_'));
      trip.modes = modes;
    }

    // to set isBus / isRail based on model info identifier
    const setSegmentTemplateBusOrTainFlag = segmentTemplate => {
      // Check if it is a bus stop
      if (segmentTemplate.modeInfo?.identifier === PUB_TRANS_BUS_IDENTIFIER)
        segmentTemplate.isBus = true
      // or if it is a rail stop
      else if (segmentTemplate.modeInfo?.identifier === PUB_TRANS_RAIL_IDENTIFIER)
        segmentTemplate.isRail = true
    }
    // to set isPublicTransit based on modeIdentifier
    const setSegmentTemplateIsPublicTransitFlag = segmentTemplate => {
      responseJSON?.groups?.map(group => group.trips.map(trip => setActiveModes(trip)));
      if (segmentTemplate.modeIdentifier === PUB_TRANS_IDENTIFIER) {
        segmentTemplate.isPublicTransit = true
        setSegmentTemplateBusOrTainFlag(segmentTemplate)
      }
    }

    this.unhash(responseJSON)

    this.sortByWeightedScoreAndAttachBestScoreAndDuration(responseJSON)

    responseJSON?.segmentTemplates?.forEach(setSegmentTemplateIsPublicTransitFlag)

    const isPublicTransit = (segment) => {
      return segment.template.modeInfo.identifier.startsWith("pt_");
    };
  
    responseJSON?.groups?.forEach(group => {
      if(group.trips?.length > 0) {
        const trip = group.trips[0];  // pick first trip as this data is sorted by weighted score in TripGoTransportServiceImpl
        
        trip.segments?.filter(isPublicTransit).forEach(segment => {
          segment.vehicleWheelchairAccessibility = this.getWheelchairAccessibility(segment);
          
          const stopss = segment.template.shapes?.filter(shape => shape.travelled).map(shape => shape.stops)
          stopss.forEach(stops => {
            stops.forEach(stop => {
              stop.wheelchairAccessibility = this.getWheelchairAccessibility(stop);
            })
          })
          
          const boardingStop = stopss[0][0];
          const lastShape = stopss[stopss.length - 1];
          const alightingStop = lastShape[lastShape.length - 1];
  
          segment.boardingStop = boardingStop;
          segment.alightingStop = alightingStop;
        });
      };
    });
    
    return responseJSON
  }

  // Get location details for given parameters from TripGo
  getLocations = async (lng, lat, radius) => {
    const url = `${TRIPGO_API}/locations.json?lng=${lng}&lat=${lat}&radius=${radius}&modes=pt_pub`
    const response = await fetch(url, {
      headers: TRIPGO_REQ_HEADERS,
    })
    const responseJSON = await response.json()
    return responseJSON
  }

  // Get departure details for given parameters from TripGo
  getDeparture = async (stops, favourites) => {
    // deep copying as we are going to merge region-stops in this object
    favourites = JSON.parse(JSON.stringify(favourites));
    const regionsStopCodes = stops.reduce((regionsStopCodes, stop) => {
      // if stop is from favorites v1 then consider them with application's default region, otherwise consider stop region
      const stopCodes = regionsStopCodes[stop.region] || [];
      stopCodes.push(stop.code);
      regionsStopCodes[stop.region] = stopCodes;
      return regionsStopCodes;
    }, favourites);

    if (Object.keys(regionsStopCodes).length) {
      const url = `${TRIPGO_API}/departures.json`
      const jsonPromises = Object.entries(regionsStopCodes).map(async ([region, embarkationStops]) => {
        const response = await fetch(url, {
          body: JSON.stringify({
            "embarkationStops": embarkationStops,
            "region": region,
          }),
          headers: TRIPGO_REQ_HEADERS,
          method: 'POST',
        });
        return response.json();
      })
      const responses = await Promise.all(jsonPromises);
      const result = {};
      // responses consist of {alerts: [], embarkationStops: [], parentInfo: {}, parentStops: [], stops: []}, so here we merge the arrays and drop the parentInfo object (which we're not using)
      responses?.forEach(response => {
        Object.entries(response).forEach(([key, value]) => {
          if (value instanceof Array) {
            const currentValues = result[key] || [];
            result[key] = currentValues.concat(value);
          }
        });

        response.embarkationStops?.forEach(embarkationStop => {
          embarkationStop.services?.forEach(service => {
            service.wheelchairAccessibility = this.getWheelchairAccessibility(service);
            delete service.wheelchairAccessible;
            
          })
          embarkationStop.wheelchairAccessibility = this.getWheelchairAccessibility(embarkationStop);
        });
        response.stops.forEach(stop => {
          stop.wheelchairAccessibility = this.getWheelchairAccessibility(stop);

          // we must set childstop's wheelchairAccessibility in every stop in stops in response.
          // if the stop have "children" properties, we show the all stops in stopslist including the children
          stop.children?.forEach(childstop => childstop.wheelchairAccessibility = this.getWheelchairAccessibility(childstop));
        });
      });
      return result;
    }
    return null;
  }
}
