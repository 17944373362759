import {IonNote} from '@ionic/react';

import {getStringFromSeconds, hourMinuteFormat} from '../util/time';

import {WheelChairAccessibilityBanner} from 'components/WheelChairAccessibility'
import './ResultGroup.scss';
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';

export function ResultGroup({children, trip}) {
  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();
  const isPublicTransit = (segment) => {
    return segment.template.modeInfo.identifier.startsWith("pt_");
  };
  
  const isTripPublicTransport = () => {
    return trip.segments?.filter(isPublicTransit)?.length > 0
  }

  return <div className="resultMain">
    <div className="journeyDetail">
      <div className="detail">
        {isTripPublicTransport() && shouldShowWheelchairAccessibility && (
          <WheelChairAccessibilityBanner wheelchairAccessibility={trip.wheelchairAccessibility}/>
        )}
        <div>
          {hourMinuteFormat.format(new Date(1000 * trip.depart))}
          {' - '}
          {hourMinuteFormat.format(new Date(1000 * trip.arrive))}
        </div>
      </div>
      <div className="detail">
        <IonNote className="priceLabel">
          {trip.moneyCost === 0 ? 'FREE' :
            trip.moneyCost ? `${trip.currencySymbol}${trip.moneyCost.toFixed(2)}` :
              undefined}
        </IonNote>
        <IonNote>
          {getStringFromSeconds(trip.arrive - trip.depart)}
        </IonNote>
      </div>
    </div>
    <div className="segmentSection">
      {children}
    </div>
  </div>
}
