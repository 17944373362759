import React from 'react';

export default function AboutHeaderScotland() {
  return <>
     <p className="ion-padding-start ion-padding-end">
     The Scotland Transportapp was developed in response to the {' '}
      <a href='https://www.transport.gov.scot/our-approach/mobility-as-a-service/maas-investment-fund-mobility-as-a-service/'>MaaS Investment Fund</a>.<br/>
      <br/>
      Our solution is a customer-facing MaaS app (iOS, Android, and mobile web) that:
    </p>
    <ul>
      <li>Aims to help Transport Scotland to tackle inequality, accessibility and mobility barriers</li>
      <li>Encourages the inclusion and participation of people with limited mobility in using a multi-modal transport app for Scotland</li>
      <li>iProvides vital information such as wheelchair friendly routes, rail stations, bus services, stops and information on the closest toilet locations and accessible parking
</li>
      <li>Uses accessibility information on bus stops and rail routes to guide customers on right footpaths, ramps, wheelchair friendly lifts and mobility options</li>
    </ul>
    <p className="ion-padding-start ion-padding-end">
    Our multi-modal transport app aims to address the following:
    </p>
    <ul>
      <li>Advanced planning of wheelchair users’ journey</li>
      <li>Information about the physical access to the mode of transport</li>
      <li>Availability of passenger boarding assistance</li>
      <li>Integrated, seamless end to end journey for travellers including disabled travellers</li>
      <li>Integrate a sustainable transport system that promotes walking, wheeling, cycling and public transport options and information</li>
    </ul>
    <br/>
  </>
}

