import {UKWheelchairProxy} from 'services/impl/UKWheelchairProxy';
import {TripGoTransportServiceImpl} from 'services/impl/TripGoTransportServiceImpl';
import {AccessibilityProxyServiceImpl} from 'services/impl/AccessibilityProxyServiceImpl'
import TutorialScotland from 'pages/TutorialScotland';
import AboutHeaderScotland from 'components/AboutHeaderScotland';
import AboutPageBackgroundImagePure from 'data/apps/scotland/About_scotland.jpg';
import resources from 'data/scotland/resources.json';

//icons
import {IonIcon} from '@ionic/react';
import {boat, bus, train, subway} from 'ionicons/icons';
import {ReactComponent as Train} from 'assets/img/raj/scotland/train.svg'

export const layerResources = resources;
export const TutorialContent = TutorialScotland;
export const geocoderBBox = [-8, 54.6, 0, 61];
export const geocoderBBoxName = 'Scotland';
export const appName = 'scotland';
export const timeZone = 'GB';
export const documentTitle = 'Scotland Transport';
export const AboutPageHeader = AboutHeaderScotland;
export const AboutPageBackgroundImage = AboutPageBackgroundImagePure;

// default value for show wheelchair accessibility or not
export const shouldShowWheelchairAccessibility = true;

// configuration for directionList
export const initialModesHide = ["bicycle"];
// configuration for transport option
export const initialConnectingModesForTransportOption = ["drive", "ride"];
export const initialRouteForTransportOption = 'wheelchair';

// Glasgow
export const geocoderProximityCoordinate = [-4.251433, 55.860916];
export const inBBox = ([x, y]) => geocoderBBox[0] <= x && x <= geocoderBBox[2] && geocoderBBox[1] <= y && y <= geocoderBBox[3];
export const initialMapBounds = [[-6, 55], [-1.8, 58.7]];
/*
  AccessibilityProxyServiceImpl - To augment trip wise and group wise wheelchair accessibility data for analytics and to show in directions/stops list screen
  UKWheelchairProxy - To augment wheelchair accessibility data for scotland using GTFS data provided by UK
*/
export const transportService = new AccessibilityProxyServiceImpl(new UKWheelchairProxy(new TripGoTransportServiceImpl()));

/**
 * Just FYI.
 * TripGo is turning all train stations into parent stations with one child.
 * so, we request PDUNDETB but the services are all for DUNDETB. Likewise it does for all stops.
 * */
export const defaultV2Favourites = {
  // Aberdeen and Dundee Rail Stations
  "UK_NorthScotland": ["ABRDEEN", "DUNDETB"],
  // Edinburgh, Glasgow Central, Glasgow Queen St Rail Stations
  "UK_SouthScotland": ["EDINBUR", "GLGCLL", "GLGQLL"],
};

export const icons = {
  'bus': <IonIcon className="mode-icon" icon={bus}/>,
  'ferry': <IonIcon className="mode-icon" icon={boat}/>,
  'metro': <IonIcon className="mode-icon" icon={train}/>,
  'train': <Train className="mode-icon"/>,
  'subway': <IonIcon className="mode-icon" icon={subway}/>,
}

/**
 * https://www.travelinescotland.com/lts/#/liveDepartures
 *                departures.json embarkationStops[0].services[0].mode
 * Bus Stops      bus (coach appears as bus in the mode field)
 * Train Stations train
 * Ferry Ports    ferry
 * Metro Stops    tram
 */
export const stopModes = [
  {label: 'Bus', value: 'bus'},
  // no ferries in Glasgow, but they are elsewhere (e.g. shetland)
  {label: 'Ferry', value: 'ferry'},
  {label: 'Train', value: 'train'},
  // Glasgow Subway & Edinburgh Trams
  {label: 'Tram, Subway, and Metro', value: 'tram'},
];

/**
 * https://www.travelinescotland.com/lts/#/travelInfo
 *       routing.json?avoidModes=pt_pub_${ptPubMode}
 *       regionInfo.json?region=UK_NorthScotland regions[0].modes.pt_pub.specificModes
 *       regionInfo.json?region=UK_SouthScotland regions[0].modes.pt_pub.specificModes
 * Bus   bus
 * Coach coach
 * Train train
 * Metro tram
 * Ferry ferry
 * Air
 */
export const directionsModes = [
  {label: 'Bus', value: 'bus'},
  {label: 'Coach', value: 'coach'},
  // no ferries in Glasgow, but they are elsewhere (e.g. shetland)
  {label: 'Ferry', value: 'ferry'},
  {label: 'Train', value: 'train'},
  // Glasgow Subway & Edinburgh Trams
  {label: 'Tram, Subway, and Metro', value: 'tram'},
];
