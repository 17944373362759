import React, {useState} from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPopover,
  IonToolbar,
} from '@ionic/react';
import './About.scss';
import {ellipsisHorizontal, ellipsisVertical} from 'ionicons/icons';
import AboutPopover from '../components/AboutPopover';

import IonPage from './JynPage';
import {AboutPageHeader, AboutPageBackgroundImage} from "../data/apps/config";

interface AboutProps { }

const About: React.FC<AboutProps> = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState();
  const [conferenceDate, setConferenceDate] = useState('2020-02-15T14:53:31+10:00');

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  return (
    <IonPage id="about-page" title="About">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}/>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="about-header">
          <div className="about-image" style={{backgroundImage: `url(${AboutPageBackgroundImage})`}}/>
        </div>
        <div className="about-info">
          <h3 className="ion-padding-top ion-padding-start">About</h3>
          <AboutPageHeader/>
          <h3 className="ion-padding-top ion-padding-start">Details</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Us
              </IonLabel>
              <IonLabel className="ion-text-end">
                Smart Cities Transport
              </IonLabel>
            </IonItem>
            <IonItem detail href='https://linkedin.com/in/jayenashar'>
              <IonLabel>
                Lead Engineer
              </IonLabel>
              <IonLabel className="ion-text-end">
                Jayen Ashar
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                Location
              </IonLabel>
              <IonLabel className="ion-text-end">
                Made with <span aria-label='love' role='img'>❤</span>️ in Sydney
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                Date
              </IonLabel>
              <IonDatetime
                displayFormat="MMM DD, YYYY"
                max="2056"
                value={conferenceDate}
                onIonChange={(e) => setConferenceDate(e.detail.value as any)}/>
            </IonItem>
          </IonList>

          <h3 className="ion-padding-top ion-padding-start">Legal</h3>

          <IonList lines="none">
            <IonItem detail
                     href='https://docs.google.com/document/d/1K3HFks1AwGEYBIg4UU_PTdsLRxocg_lYRriA5L0H5KU/edit?usp=sharing#bookmark=id.5srv0me8yqlj'
                     id='terms'>
              <IonLabel>
                Terms and Conditions
              </IonLabel>
            </IonItem>
            <IonItem detail
                     href='https://docs.google.com/document/d/1K3HFks1AwGEYBIg4UU_PTdsLRxocg_lYRriA5L0H5KU/edit?usp=sharing#bookmark=id.petv5i1qvp0y'
                     id='privacy-policy'>
              <IonLabel>
                Privacy Policy
              </IonLabel>
            </IonItem>
          </IonList>

        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)}/>
      </IonPopover>
    </IonPage>
  );
};

export default React.memo(About);
