import React from 'react';
import {connect} from '../data/connect';
import {Redirect} from 'react-router';
import {get, init} from '../util/storage';

init('lasturl', '');

const HomeOrTutorial: React.FC<StateProps> = () => <Redirect to={ get('lasturl') || '/tutorial' }/>;

export default connect<{}, StateProps, {}>({
  component: HomeOrTutorial,
});
