import React from 'react';
import {IonCheckbox, IonIcon, IonItem, IonLabel, IonList} from '@ionic/react';
import {logoBuffer} from 'ionicons/icons';
import './LayerControl.scss';
import {
  IonButton,
  IonContent,
  IonModal,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonFooter,
} from '@ionic/react';
import {appName, layerResources} from '../data/apps/config';
import {get, init, set} from '../util/storage';

import {sendEvent} from 'components/Analytics';

const DEFAULT_LAYERS = layerResources.filter(resource => resource['default'] === true).map(resource => {
  return resource['name'];
});

init('visible-layers-to-show', DEFAULT_LAYERS);

const layerTypesGetOptions = {
  circle: hue => ({
    'layout': {
      // make layer visible by default
      'visibility': 'visible',
    },
    'paint':  {
      'circle-color':  `hsl(${hue}, 100%, 50%)`,
      'circle-radius': {
        "stops": [
          // zoom is 13 -> circle radius will be 2px
          [13, 2],
          // zoom is 16 -> circle radius will be 16px
          [16, 16],
        ],
      },
    },
    'type':   'circle',
  }),
  fill:   hue => ({
    'layout': {
      // make layer visible by default
      'visibility': 'visible',
    },
    'paint':  {
      'fill-color': `hsl(${hue}, 100%, 50%)`,
    },
    'type':   'fill',
  }),
  line:   hue => ({
    'layout': {
      'line-cap':   'round',
      'line-join':  'round',
      // make layer visible by default
      'visibility': 'visible',
    },
    'paint':  {
      'line-color': `hsl(${hue}, 100%, 50%)`,
      'line-width': {
        "stops": [
          // zoom is 13 -> line width will be 2px
          [13, 2],
          // zoom is 16 -> line width will be 4px
          [16, 4],
        ],
      },
    },
    'type':   'line',
  }),
};

const setIsVisible = (map, resource, e) => {
  /** @type string */
  const id        = resource.name;
  const isVisible = e.detail.checked;

  // update the user's settings in storage
  const layers = get('visible-layers-to-show').filter(layer => layer !== id)
  if (isVisible) {
    layers.push(id)
  }
  set('visible-layers-to-show', layers)

  // lazy load layer the first time it is made visible, and then toggle the layer's visibility after that
  // contours are 5MB.  download only when needed, and only download once
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, 'visibility', isVisible ? 'visible' : 'none');
  } else {
    const layerOptions = layerTypesGetOptions[resource.layerType](resource.hue);
    map.addLayer({
      'id':     id,
      'source': {
        data: `/assets/data/${appName}/geojson/${resource.geojson}`,
        type: 'geojson',
      },
      ...layerOptions,
    });
  }
};

export default function LayerControl({map}) {
  const [isOpen, setIsOpen]       = React.useState(false);
  const [resources, setResources] = React.useState([]);

  const isLayerVisible = id => map.getLayer(id) && map.getLayoutProperty(id, 'visibility') === 'visible';

  const onVisibleChange = resource => e => {
    sendEvent('Layer', e.detail.checked? 'show': 'hide', resource.name);
    setIsVisible(map, resource, e);
  }

  React.useEffect(() => {
    (async () => {
      const resources = layerResources;
      setResources(resources);
      /** @type string[] */
      const layers = get('visible-layers-to-show');
      // if the user disabled all layers, enable the defaults because we assume that's what Waverley would want
      if (layers.length === 0) {
        layers.push(...DEFAULT_LAYERS);
      }
      map.once('idle', () => resources.filter(resource => layers.includes(resource.name))
                                      .forEach(resource => setIsVisible(map, resource, {detail: {checked: true}})));
    })();
  }, [map]);


  return (
    <div className='layer-control'>
      <IonIcon icon={logoBuffer} onClick={() => setIsOpen(true)} style={{fontSize: '24px'}}/>
      {isOpen &&
       <>
         <div className='backdrop' onClick={() => setIsOpen(false)}/>
         <div className='details'>
         <IonModal className='card'
                   isOpen={true}
                   swipeToClose={true}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Details</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent class="outer-content">
              <IonList>
                {resources.map((resource) =>
                  <IonItem key={resource.name}>
                    <IonLabel style={{background: `hsl(${resource.hue}, 100%, 80%)`}}>
                      {resource.dataFeaturesLength} {resource.name}
                    </IonLabel>
                    <IonCheckbox checked={isLayerVisible(resource.name)}
                                 onIonChange={onVisibleChange(resource)}
                                 slot='start'/>
                  </IonItem>,
                )}
              </IonList>
            </IonContent>
            <IonFooter>
              <IonToolbar>
                <IonButton slot='end' fill='clear' onClick={() => setIsOpen(false)}>Done</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
         </div>
       </>}
    </div>
  );
}
