import React from 'react';
import { IonHeader, IonToolbar, IonContent, IonButtons, IonBackButton, IonButton, IonIcon, IonText, IonList, IonItem, IonLabel } from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { starOutline, star, share, cloudDownload } from 'ionicons/icons';
import './SessionDetail.scss';
import { addFavourite, removeFavourite } from '../data/sessions/sessions.actions';
import { Session } from '../models/Schedule';

import IonPage from './JynPage';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  session?: Session;
  favouriteSessions: string[],
};

interface DispatchProps {
  addFavourite: typeof addFavourite;
  removeFavourite: typeof removeFavourite;
}

type SessionDetailProps = OwnProps & StateProps & DispatchProps;

const SessionDetail: React.FC<SessionDetailProps> = ({ session, addFavourite, removeFavourite, favouriteSessions }) => {
  
  if (!session) {
    return <div>Session not found</div>
  }

  const isFavourite = favouriteSessions.indexOf(session.id) > -1;

  const toggleFavourite = () => {
    isFavourite ? removeFavourite(session.id) : addFavourite(session.id);
  };
  const shareSession = () => { };
  const sessionClick = (text: string) => {
    console.log(`Clicked ${text}`);
  };

  return (
    <IonPage id="session-detail-page" title="Session Detail"> 
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/schedule"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => toggleFavourite()}>
              {isFavourite ?
                <IonIcon slot="icon-only" icon={star}></IonIcon> :
                <IonIcon slot="icon-only" icon={starOutline}></IonIcon>
              }
            </IonButton>
            <IonButton onClick={() => shareSession}>
              <IonIcon slot="icon-only" icon={share}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">
          <h1>{session.name}</h1>
          {session.tracks.map(track => (
            <span key={track} className={`session-track-${track.toLowerCase()}`}>{track}</span>
          ))}
          <p>{session.description}</p>
          <IonText color="medium">
            {session.timeStart} &ndash; {session.timeEnd}
            <br />
            {session.location}
          </IonText>
        </div>
        <IonList>
          <IonItem onClick={() => sessionClick('watch')} button>
            <IonLabel color="primary">Watch</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick('add to calendar')} button>
            <IonLabel color="primary">Add to Calendar</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick('mark as unwatched')} button>
            <IonLabel color="primary">Mark as Unwatched</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick('download video')} button>
            <IonLabel color="primary">Download Video</IonLabel>
            <IonIcon slot="end" color="primary" size="small" icon={cloudDownload}></IonIcon>
          </IonItem>
          <IonItem onClick={() => sessionClick('leave feedback')} button>
            <IonLabel color="primary">Leave Feedback</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    session: selectors.getSession(state, OwnProps),
    favouriteSessions: state.data.favourites
  }),
  mapDispatchToProps: {
    addFavourite,
    removeFavourite
  },
  component: withRouter(SessionDetail)
})
