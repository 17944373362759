import React, {useState} from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import {directionsModes} from '../data/apps/config';

const getCheckboxItem = (state, setState) => ({label, value}) => {
  const onIonChange = e => {
    if (e.detail.checked) {
      setState([...state, value]);
    } else {
      setState(state.filter(stateValue => stateValue !== value));
    }
  };

  return <IonItem>
    <IonLabel>{label}</IonLabel>
    <IonCheckbox checked={state.includes(value)}
                 onIonChange={onIonChange}
                 slot='start'/>
  </IonItem>
};

/**
 * @type React.FC
 */
export const TransportOptions = ({presentingElement, ...props}) => {
  // driving options: Avoid motorways, Avoid toll roads, Avoid ferries
  // public transport options
  const [preferredModes, setPreferredModes]   = useState(props.preferredModes);
  const [route, setRoute]                     = useState(props.route);
  const [connectingModes, setConnectingModes] = useState(props.connectingModes);

  const getPreferredModeItem  = getCheckboxItem(preferredModes, setPreferredModes);
  const getConnectingModeItem = getCheckboxItem(connectingModes, setConnectingModes);

  return (
    <IonModal cssClass='transport-options'
              isOpen={true}
              onDidDismiss={props.close}
              presentingElement={presentingElement}
              swipeToClose={true}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Transport Options</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content">
        <IonList>
          <IonListHeader><IonLabel>Preferred Mode</IonLabel></IonListHeader>
          {directionsModes.map(getPreferredModeItem)}
        </IonList>
        <IonList>
          <IonListHeader><IonLabel>Routes</IonLabel></IonListHeader>
          <IonRadioGroup onIonChange={e => setRoute(e.detail.value)} value={route}>
            <IonItem>
              <IonLabel>Best route</IonLabel>
              <IonRadio slot="start" value="best"/>
            </IonItem>
            <IonItem>
              <IonLabel>Fewer transfers</IonLabel>
              <IonRadio slot="start" value="fewer"/>
            </IonItem>
            <IonItem>
              <IonLabel>Less walking</IonLabel>
              <IonRadio slot="start" value="less"/>
            </IonItem>
            <IonItem>
              <IonLabel>Wheelchair accessible</IonLabel>
              <IonRadio slot="start" value="wheelchair"/>
            </IonItem>
          </IonRadioGroup>
        </IonList>
        <IonList>
          <IonListHeader><IonLabel>Connecting Modes</IonLabel></IonListHeader>
          {getConnectingModeItem({label: 'Drive', value: 'drive'})}
          {getConnectingModeItem({label: 'Ride services', value: 'ride'})}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton slot='start' fill='clear' onClick={props.close}>Cancel</IonButton>
          <IonButton slot='end' onClick={() => {
            props.setOptions({connectingModes, preferredModes, route});
            props.close();
          }}>Done</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
