/* eslint-disable react-hooks/rules-of-hooks */
import {SharedStorageStateTarget} from "./storage";

// setting filters
export const allModes = ['bus', 'ferry', 'train', 'tram', 'metro'];

const STOP_LIST_FILTER = new SharedStorageStateTarget('stop-list-filter', {
  modes: allModes,
  radius: 1000,
  wheelChairAccessibility: "all",
});
export const useStopListFilterState = () => STOP_LIST_FILTER.useSharedState()
