import {TripGoTransportServiceImpl} from 'services/impl/TripGoTransportServiceImpl';
import {AccessibilityProxyServiceImpl} from 'services/impl/AccessibilityProxyServiceImpl'
import {NSWheelchairProxy} from 'services/impl/NSWheelchairProxy'
import TutorialWaverley from 'pages/TutorialWaverley';
import AboutHeaderWaverley from 'components/AboutHeaderWaverley';
import AboutPageBackgroundImagePure from 'data/apps/waverley/About_waverley.jpg';
import resources from 'data/waverley/resources.json';

//icons
//flag-icons
import {ReactComponent as Bus} from 'assets/img/raj/sydney/Bus.svg'
import {ReactComponent as Ferry} from 'assets/img/raj/sydney/Ferry.svg'
import {ReactComponent as LightRail} from 'assets/img/raj/sydney/LightRail.svg'
import {ReactComponent as Train} from 'assets/img/raj/sydney/Train.svg'
import {ReactComponent as Metro} from 'assets/img/raj/sydney/Metro.svg'

export const layerResources = resources;
export const TutorialContent = TutorialWaverley;
export const geocoderBBox = [149.5, -35.2, 152.67, -32];
export const geocoderBBoxName = 'Sydney';
export const appName = 'waverley';
export const timeZone = 'Australia/Sydney';
export const documentTitle = 'Waverley Transport';
export const AboutPageHeader = AboutHeaderWaverley;
export const AboutPageBackgroundImage = AboutPageBackgroundImagePure;

// default value for show wheelchair accessibility or not
export const shouldShowWheelchairAccessibility = false;


// configuration for directionList
export const initialModesHide = [];
// configuration for transport option
export const initialConnectingModesForTransportOption = [];
export const initialRouteForTransportOption = 'best';

// Bondi Junction station
export const geocoderProximityCoordinate = [151.24846066671, -33.891581534381];
export const inBBox = ([x, y]) => geocoderBBox[0] <= x && x <= geocoderBBox[2] && geocoderBBox[1] <= y && y <= geocoderBBox[3];
export const initialMapBounds = [[151.24, -33.9], [151.29, -33.88]];

/*
  AccessibilityProxyServiceImpl - To augment trip wise and group wise wheelchair accessibility data for analytics and to show in directions/stops list screen
  NSWheelchairProxy - To override wheelchair accessibility for Redfern Station Platform 6 and Redfern Station Platform 7
*/
export const transportService = new AccessibilityProxyServiceImpl(new NSWheelchairProxy(new TripGoTransportServiceImpl()));

/**
 * bondi junction stations:'202291', '202292'
 * bondi beach towards city: '202653'
 * */
export const defaultV2Favourites = {
  "AU_NSW_Sydney": ["202291", "202292", "202653"],
};

export const icons = {
  'bus': <Bus className="mode-icon"/>,
  'ferry': <Ferry className="mode-icon"/>,
  'metro': <Metro className="mode-icon"/>,
  'subway': <Metro className="mode-icon"/>,
  'train': <Train className="mode-icon"/>,
  'tram': <LightRail className="mode-icon"/>,
}

/**
 * value is from tripgo departures.json embarkationStops[0].services[0].mode
 */
export const stopModes = [
  {label: 'Bus', value: 'bus'},
  {label: 'Ferry', value: 'ferry'},
  {label: 'Metro', value: 'metro'},
  {label: 'Train', value: 'train'},
  {label: 'Tram and light rail', value: 'tram'},
];

export const directionsModes = [
  {label: 'Bus', value: 'bus'},
  {label: 'Ferry', value: 'ferry'},
  {label: 'Metro', value: 'metro'},
  {label: 'Train', value: 'train'},
  {label: 'Tram and light rail', value: 'tram'},
];
