import React from 'react';
import {bicycle, boat, bus, car, gitCommit, globe, key, school, time, train, walk} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';
import {ReactComponent as WheelChair} from 'assets/img/accessibility/accessible.svg';
import {icons as rajIcons} from 'data/apps/config';

export const ionicons = {
  bicycle,
  bus,
  car,
  collect: key,
  ferry: boat,
  globe,
  school,
  train,
  transfer: gitCommit,
  wait: time,
  walk,
};

// cache so we only fetch the icon once
const localIcons = {
  'carNextDoor': <LocalIcon mode='carNextDoor'/>,
  'motorbike': <LocalIcon mode='motorbike'/>,
  'parking': <LocalIcon mode='parking'/>,
  'publicTransport': <LocalIcon mode='publicTransport'/>,
  'school-bus': <LocalIcon mode='school-bus'/>,
  'shuttle': <LocalIcon mode='shuttle'/>,
  'taxi': <LocalIcon mode='taxi'/>,
  'tram': <LocalIcon mode='tram'/>,
  'wheelchair': <WheelChair className="mode-icon"/>,
  'subway' : <LocalIcon mode='subway'/>,
};

function TripGoIcon({url}) {
  const [__html, set__HTML] = React.useState('');
  React.useEffect(() => {
    (async () => {
      const response = await fetch(url);
      const text = await response.text();
      const doc = new DOMParser().parseFromString(text, 'image/svg+xml');
      doc.documentElement.removeAttribute('width');
      doc.documentElement.removeAttribute('height');
      const __html = new XMLSerializer().serializeToString(doc.documentElement);
      set__HTML(__html);
    })();
  }, [url]);
  return <span className="mode-icon" dangerouslySetInnerHTML={{__html}}/>;
}

function LocalIcon({mode}) {
  return <TripGoIcon url={`/assets/icon/modeicons/ic-${mode}-24px.svg`}/>;
}

function RemoteIcon({mode}) {
  return <TripGoIcon url={`https://api.tripgo.com/v1/modeicons/icon-mode-${mode}.svg`}/>
}

// TODO: ModeInfoIcon component
// automatically decide between modeInfo.localIcon and modeInfo.remoteIcon
export function getIcon(modeInfo) {
  // can't simply do this because localIcon may be in modeInfo but we don't have it locally
  // return getModeIcon(modeInfo.localIcon ?? modeInfo.remoteIcon)

  // icons from config.js
  if (modeInfo.localIcon in rajIcons) {
    return rajIcons[modeInfo.localIcon]
  }
  if (modeInfo.localIcon in ionicons) {
    return <IonIcon className="mode-icon" icon={ionicons[modeInfo.localIcon]}/>;
  }
  if (modeInfo.localIcon in localIcons) {
    return localIcons[modeInfo.localIcon];
  }
  if (modeInfo.remoteIcon) {
    return <RemoteIcon mode={modeInfo.remoteIcon}/>;
  }
  return modeInfo.identifier;
}

// TODO: ModeIcon component
// get icon for mode.  e.g., publicTransport, globe, walk, bicycle, taxi
// this is for directions request where we don't have modeInfo
export function getModeIcon(mode) {
  return mode in ionicons ?
    <IonIcon icon={ionicons[mode]}/> :
    mode in localIcons ?
      localIcons[mode] :
      <RemoteIcon mode={mode}/>;
}
