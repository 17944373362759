import {isPlatform} from '@ionic/react';

// https://github.com/ionic-team/capacitor/issues/3210
if (isPlatform('ios')) {
  window.XMLHttpRequest = class extends window.XMLHttpRequest {
    open(...args) {
      const retval = super.open(...args);
      this.setRequestHeader('Referer', window.location.toString());
      return retval;
    }
  }
}
