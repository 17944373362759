import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {chevronDown} from 'ionicons/icons';
import React, {useRef} from 'react';
import moment from 'moment-timezone';
import {useHistory, withRouter, useLocation} from 'react-router';

import Geocoder, {YOUR_LOCATION} from '../util/Geocoder';
import IonPage from './JynPage';
import IonRefresher from '../components/JynRefresher';
import {GeolocationErrorCard, OutofMetroCard, UserErrorCard} from '../components/errorcards';
import {ResultGroup} from '../components/ResultGroup';
import {Segments} from '../components/Segments';
import {TimeOptions} from '../components/TimeOptions';
import {TransportOptions} from "../components/TransportOptions";
import {accessToken} from '../util/mapbox-gl';
import {get, init, set} from '../util/storage';
import {getGeolocation, hasGeolocatePermission} from "../util/geolocate";
import {getStringFromSeconds, hourMinuteFormat} from '../util/time';
import {getModeIcon} from '../components/Icon';
import {transportService, inBBox, timeZone, initialModesHide} from '../data/apps/config';
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {useTransportOptionsState} from 'util/transport-options';
import './DirectionsList.scss';

const timeAnchorsLabel = {
  arriveBefore: 'Arrive by',
  departAfter: 'Depart at',
};

let directionsFrom, directionsTime;

init('directions-modes-to-hide', initialModesHide);

const getParam = function (location) {
  const centre = location.centre;
  const input = location.input;
  let param = `(${centre[1]},${centre[0]})`;
  if (input !== YOUR_LOCATION) {
    param += `"${input}`;
  }
  return param;
};

const doFetch = (from, to, timeOptions, transportOptions, setResults, ionRefresher, setHasAttemptedFetch) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`${Date.now()}\tfrom, to`, JSON.stringify(from), JSON.stringify(to));
  }
  if (from.centre && to.centre) {
    const fromParam = getParam(from);
    const toParam = getParam(to);
    setResults({});

    const globeModes = ['cy_bic', 'pt_pub'];
    if (transportOptions.connectingModes.includes('drive')) {
      globeModes.push('me_car');
    }
    if (transportOptions.connectingModes.includes('ride')) {
      globeModes.push('ps_tax');
    }

    const fetches = [
      fetchForMode('publicTransport', ['pt_pub']),
      fetchForMode('walk', ['wa_wal']),
      fetchForMode('globe', globeModes),
      fetchForMode('taxi', ['ps_tax']),
      fetchForMode('bicycle', ['cy_bic']),
    ];
    if (ionRefresher) {
      ionRefresher.activateRefresher();
      Promise.all(fetches).then(() => ionRefresher.complete());
    }
    async function fetchForMode(id, modes) {
      const modeParams = modes.map(mode => `modes=${mode}`).join('&');
      const unix =
        timeOptions.date && timeOptions.time ?
          moment.tz(`${timeOptions.date} ${timeOptions.time}`, timeZone).unix() :
          moment().unix();
      const ptPubModes = ['bus', 'metro', 'train', 'tram', 'ferry'];
      let avoidModesParam = '';
      if (transportOptions.preferredModes.length) {
        const avoidModesParams = ptPubModes.filter(ptPubMode => !transportOptions.preferredModes.includes(ptPubMode))
          .map(ptPubMode => `avoidModes=pt_pub_${ptPubMode}`);
        avoidModesParam = `&${avoidModesParams.join('&')}`
      }
      const routeParam =
        transportOptions.route === 'fewer' ? '&wp=(0,0,0,2)' :
        transportOptions.route === 'less' ? '&wp=(0,0,2,0)&ws=0' :
        transportOptions.route === 'wheelchair' ? '&wheelchair=true' :
        '';

      const responseJSON = await transportService.getRouting(fromParam, timeOptions.anchor, unix, toParam, modeParams, avoidModesParam, routeParam)
      setHasAttemptedFetch(true);
      setResults(prevState => ({...prevState, [id]: responseJSON}));
      // Before calling getRouting function, in AccessibilityProxyServiceImpl.js, we get inaccessible stops from each group's first trip. So if resonseJSON contains inaccessible stops, we have to rule them out and get trips again.
      // This means that we have to add inaccessible stops to the URL calling TripGoAPI's routing.json and send it.
      // The parameter for this is neverAllowStops'.

      const neverAllowStops = responseJSON.groups?.reduce((p1, group) => p1.concat( group.inaccessibleStops ), []);

      const isPublicTransit = (segment) => {
        return segment.template.modeInfo.identifier.startsWith("pt_");
      };

      const bestJourneyHasInaccessibleStops = (json) => {
        const segments = json.groups?.[0]?.trips[0]?.segments?.filter(isPublicTransit);
        const hasInaccessible = (segment) => segment.boardingStopAccessibility === false || segment.alightingStopAccessibility === false;
        return segments?.some(hasInaccessible);
      }

      const hasJourneyInaccessible = bestJourneyHasInaccessibleStops(responseJSON);
      if (transportOptions.route === 'wheelchair' && hasJourneyInaccessible ) {
        const rerouteResponseJSON = await transportService.getRouting(fromParam, timeOptions.anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops);

        const includesGroup = (json, group) => {
          for(const g of json.groups) {
            if(compareGroup(g, group))
              return true;
          }
          return false;
        }


        //group compare same as first trip(from each group)'s compare. so we must compare frist trip from each group.
        const compareGroup = (group1, group2) => {
          if (group1.trips[0].segments.length !== group2.trips[0].segments.length)
            return false;
          return group1.trips[0].segments.every((segment, s) => segment.segmentTemplateHashCode === group2.trips[0].segments[s].segmentTemplateHashCode);
        }

        //get MergedResponse(responseJSON+responseJSON2 without any duplications)
        const getMergedResponseJSON = (responseJSON, responseJSON2) => {
          const mergedResponseJSON = JSON.parse(JSON.stringify(responseJSON));
          for (const g of responseJSON2?.groups) {
            if(!includesGroup(mergedResponseJSON, g))   // we need to know if the group repeats.
              mergedResponseJSON.groups.push(g);
          }
          return mergedResponseJSON;
        }

        const mergedResponseJSON = getMergedResponseJSON(responseJSON,rerouteResponseJSON);

        setResults(prevState => ({...prevState, [id]: mergedResponseJSON}));
      }
    }
  }
};


const DirectionsList = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [hasAttemptedFetch, setHasAttemptedFetch] = React.useState(false);

  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();
  const pageRef = React.useRef();

  const [from, setFrom] = React.useState(directionsFrom || {});
  const cacheFrom = from => {
    setFrom(from);
    if (from.input === YOUR_LOCATION) {
      const {centre, ...rest} = from;
      directionsFrom = {...rest};
    } else {
      directionsFrom = from;
    }
  };
  const [fromGeocoder, setFromGeocoder] = React.useState(null);
  const [to, setTo] = React.useState({
    centre: urlSearchParams.get('toCentre')?.split(',').map(Number),
    input: urlSearchParams.get('toInput'),
  });
  const [toGeocoder, setToGeocoder] = React.useState(null);
  const [geolocationError, setGeolocationError] = React.useState('');
  const [showTimeOptions, setShowTimeOptions] = React.useState(false);
  const [timeOptions, setTimeOptions] = React.useState(directionsTime || {
    anchor: 'departAfter',
    date: null, // yyyy-mm-dd
    time: null, // hh:mm
  });
  const cacheTimeOptions =
    timeOptions => setTimeOptions(directionsTime = timeOptions);
  const [showTransportOptions, setShowTransportOptions] = React.useState(false);
  const [transportOptions, setTransportOptions] = useTransportOptionsState();
  // TODO: switch to useReducer
  const [results, setResults] = React.useState({});
  // TODO: switch to useReducer
  const [modesToHide, setModesToHide] = React.useState(get('directions-modes-to-hide'));
  const saveModesToHide = getModesToHide =>
    setModesToHide(oldModesToHide => set('directions-modes-to-hide', getModesToHide(oldModesToHide)));
  const ionRefresherRef = useRef(null);
  const history = useHistory();

  const resetGeocoder = (geocoder, input) => {
    // Set input value to passed value and clear everything else.
    geocoder._inputEl.value = input;
    geocoder._typeahead.selected = null;
    geocoder._typeahead.clear();
  }

  const getGeocoderCallback = (state, setState, setGeocoder, placeholder, statename) => element => {
    if (element) {
      const geocoder = new Geocoder({
        accessToken,
        localGeocoder,
        minLength: 0,
        placeholder: placeholder,
      });
      geocoder.addTo(`#${element.id}`); // does not return geocoder
      if (state.input) {
        resetGeocoder(geocoder, state.input);
      } else {
        if (hasGeolocatePermission() && statename === 'from') {
          geocoder._inputEl.value = YOUR_LOCATION;
        }
      }
      geocoder.on('result', (result) => {
        if (result.result.geolocate) {
          getGeolocation().then(locationOrError => {
            if (locationOrError.location) {
              setState({centre: locationOrError.location, input: result.result.place_name});
              setGeolocationError('');
            }
            if (locationOrError.error) {
              setGeolocationError(locationOrError.error);
            }
          });
        } else {
          setState({centre: result.result.center, input: result.result.place_name});
        }
      });
      setGeocoder(geocoder);

      function localGeocoder(query) {
        if (YOUR_LOCATION.toLowerCase().includes(query.toLowerCase())) {
          return [{
            geolocate: true,
            geometry: null,
            id: '0',
            place_name: YOUR_LOCATION,
            properties: {},
            type: 'Feature',
          }];
        }
      }
    }
  };
  // callback refs run when dependencies change, or the ref is mounted/unmounted
  // we only want to run this callback ref once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fromLocation = React.useCallback(getGeocoderCallback(from, cacheFrom, setFromGeocoder, 'Choose starting point', 'from'), []);
  // callback refs run when dependencies change, or the ref is mounted/unmounted
  // we only want to run this callback ref once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toLocation = React.useCallback(getGeocoderCallback(to, setTo, setToGeocoder, 'Choose destination', 'to'), []);

  // purpose of this code is to re-geolocate before routing
  const doRefresh = () => {
    const isFromYourLocation = from.input === YOUR_LOCATION;
    const isToYourLocation = to.input === YOUR_LOCATION;
    if (isFromYourLocation || isToYourLocation) {
      getGeolocation().then(locationOrError => {
        if (locationOrError.error) {
          setGeolocationError(locationOrError.error);
          ionRefresherRef.current.complete();
        } else {
          setGeolocationError('');
          const location = {centre: locationOrError.location, input: YOUR_LOCATION};
          if (isFromYourLocation) {
            cacheFrom(location);
          }
          if (isToYourLocation) {
            setTo(location);
          }
        }
      })
    } else {
      doFetch(from, to, timeOptions, transportOptions, setResults, ionRefresherRef.current, setHasAttemptedFetch);
    }
  }

  React.useEffect(() => {
    const newInput = urlSearchParams.get('toInput');
    if (newInput && to.input !== newInput) {
      if (toGeocoder) {
        resetGeocoder(toGeocoder, newInput);
      }
    }
  // urlSearchParams changes when location.search changes
  // to.input should not trigger a reset
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, toGeocoder]);

  // purpose of this is to automatically get directions after the map screen
  React.useEffect(() => {
    if (hasGeolocatePermission() && (!from.input || from.input === YOUR_LOCATION)) {
      ionRefresherRef.current?.activateRefresher();
      getGeolocation().then(locationOrError => {
        if (locationOrError.error) {
          setGeolocationError(locationOrError.error);
          ionRefresherRef.current.complete();
        } else {
          setGeolocationError('');
          cacheFrom({centre: locationOrError.location, input: YOUR_LOCATION})
        }
      });
    }
    // we only want this effect to run on mount
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (toGeocoder && from.centre) {
      toGeocoder.setProximity(from.centre);
    }
  }, [toGeocoder, from.centre]);

  React.useEffect(() => {
    if (fromGeocoder && to.centre) {
      fromGeocoder.setProximity(to.centre);
    } else {
      ionRefresherRef.current.complete();
    }

  }, [fromGeocoder, to.centre]);

  React.useEffect(()=>{
    if (!shouldShowWheelchairAccessibility && transportOptions.route === 'wheelchair'){
      setTransportOptions({...transportOptions, route: 'best'});
    }
  // custom hooks: https://github.com/facebook/react/issues/16873
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowWheelchairAccessibility])

  React.useEffect(() => doFetch(from, to, timeOptions, transportOptions, setResults, ionRefresherRef.current, setHasAttemptedFetch),
                  [from, to, timeOptions, transportOptions]);
  //we seted tripOrder in each groups to order the trips by accessible state. in AucessibilityProxyServiceImpl.js"
  //The smaller the tripOrder, the higher the priority.
  //so we sort items by tripOrder first, and if the tripOrder is equals each other, then sort items by group's score.
  const modes = Object.keys(results).filter(mode => results[mode].score);

  if(transportOptions.route === 'wheelchair'){
    modes.sort((a,b) => results[a].tripOrder !== results[b].tripOrder ? results[b].tripOrder - results[a].tripOrder : a === 'bicycle' ? 1 : b === "bicycle" ? -1 : results[a].score - results[b].score);
  }
  else{
    modes.sort((a, b) => results[a].tripOrder !== results[b].tripOrder ? results[b].tripOrder - results[a].tripOrder : a === 'car' ? 1 : b === 'car' ? -1 : results[a].score - results[b].score);
  }

  const groupModes = [];
  for (const mode in results) {
    const result = results[mode];
    const groups = result.groups;
    if (groups) {
      groupModes.push(...groups.map(group => ({group, mode})));
    }
  }
  if(transportOptions.route === 'wheelchair'){
    groupModes.sort((a, b) => a.group.tripOrder !== b.group.tripOrder ? b.group.tripOrder-a.group.tripOrder : a.group.score - b.group.score);
  }
  else{
    groupModes.sort((a, b) => a.group.tripOrder !== b.group.tripOrder ? b.group.tripOrder-a.group.tripOrder : a.mode === 'car' ? 1 : b.mode === 'car' ? -1 : a.group.score - b.group.score);
  }

  // These are just used for display, so it's ok to display them in local time, but let's use Sydney time anyway
  const today = moment().tz(timeZone).format(moment.HTML5_FMT.DATE);
  const chosenDate = timeOptions.date && timeOptions.time ?
    moment.tz(`${timeOptions.date} ${timeOptions.time}`,
              timeZone).toDate() :
    new Date();
  const time = hourMinuteFormat.format(chosenDate);
  const datetime = chosenDate.toLocaleString(undefined,
                                             {
                                               day: 'numeric',
                                               hour: 'numeric',
                                               minute: 'numeric',
                                               month: 'short',
                                               timeZone: timeZone,
                                               weekday: 'short',
                                             });
  return (
    <IonPage id='speaker-list' title="Directions List" ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonButtons slot='start'/>
          <IonTitle>Directions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={`outer-content`}>
        <IonRefresher slot="fixed"
                      ref={ionRefresherRef}
                      onIonRefresh={doRefresh}>
          <IonRefresherContent/>
        </IonRefresher>

        <div id='from-location' className='geocoder from-location' ref={fromLocation}/>
        <div id='to-location' className='geocoder to-location' ref={toLocation}/>
        {Object.values(results).length > 0 && <UserErrorCard response={Object.values(results)[0]}/> }
        {geolocationError ?
          <GeolocationErrorCard geolocationError={geolocationError} onclickFunction={doRefresh}/> :
          <OutofMetroCard isOutofArea={((from?.centre && !inBBox(from.centre)) || (to?.centre && !inBBox(to.centre)))} pageName='Directions'/>}

        <div className='mode-durations'>
          {modes.map(mode => {
            const modeIcon = getModeIcon(mode);
            const visible = !modesToHide.includes(mode);
            const onClick = () => {
              saveModesToHide(modesToHide => modesToHide.includes(mode) ?
                modesToHide.filter(modeToHide => modeToHide !== mode) :
                [...modesToHide, mode]);
            };

            return <IonChip color='primary' onClick={onClick} outline={!visible} key={mode}>
              {modeIcon} <IonLabel>{getStringFromSeconds(results[mode].duration)}</IonLabel>
            </IonChip>;
          })}
        </div>
        <div className='query-options'>
          {showTimeOptions &&
            <TimeOptions close={() => setShowTimeOptions(false)}
                         {...timeOptions}
                         presentingElement={pageRef.current}
                         setOptions={cacheTimeOptions}/>
          }
          <IonButton fill='clear' onClick={() => setShowTimeOptions(true)}>
            {timeAnchorsLabel[timeOptions.anchor]}
            {!timeOptions.date || timeOptions.date === today ?
              ` ${time}` :
              ` ${datetime.replace(/ /g, "\u00a0")}`} <IonIcon icon={chevronDown}/>
          </IonButton>
          {showTransportOptions &&
            <TransportOptions close={() => setShowTransportOptions(false)}
                              {...transportOptions}
                              presentingElement={pageRef.current}
                              setOptions={setTransportOptions}/>
          }
          <IonButton fill='clear' onClick={() => setShowTransportOptions(true)}>Options</IonButton>
        </div>
        <IonList>
          {groupModes.filter(({mode}) => !modesToHide.includes(mode)).map(({group, mode}, gm) => {
            const trip = group.trips[0];
            if(!trip.modes.includes('cy_bic') || !modesToHide.includes('bicycle')) {
              return <IonItem key={gm} className="summary-section"
                              onClick={() => history.push('/tabs/directions-details', {trip})}>
              <ResultGroup trip={trip}>
                <Segments trip={trip}/>
              </ResultGroup>
            </IonItem>;
            }
            else{
              return null;
            }
          })}
          {hasAttemptedFetch && <IonItem>
            <IonLabel>
              Powered by SkedGo/TripGo
          </IonLabel>
          </IonItem>}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(DirectionsList);
