import React from 'react';
import { isPlatform, IonList, IonItem, IonLabel } from '@ionic/react';
import {useLocation} from "react-router";
import {appName} from 'data/apps/config';

interface AboutPopoverProps {
  dismiss: () => void;
};

const AboutPopover: React.FC<AboutPopoverProps> = ({dismiss}) => {

  const location = useLocation();
  const capitalizeAppName = appName[0].toUpperCase() + appName.substr(1).toLowerCase()

  const close = (url: string) => {
    window.open(url, '_blank');
    dismiss();
  };

  const newline = isPlatform('android') ? '<br/>' : '';
  const supportURL = `mailto:support@smartcitiestransport.com?subject=${encodeURIComponent(`[${capitalizeAppName} Transport] Support`)}&body=${encodeURIComponent(`${newline}
${newline}
For the developer:${newline}
URL: ${window.location}${newline}
state: ${JSON.stringify(location.state)}${newline}
User Agent: ${window.navigator.userAgent}`)}`;

  return (
    <IonList>
      {/*<IonItem button onClick={() => close('https://ionicframework.com/getting-started')}>*/}
      {/*  <IonLabel>Learn Ionic</IonLabel>*/}
      {/*</IonItem>*/}
      {/*<IonItem button onClick={() => close('https://ionicframework.com/docs/react')}>*/}
      {/*  <IonLabel>Documentation</IonLabel>*/}
      {/*</IonItem>*/}
      {/*<IonItem button onClick={() => close('https://showcase.ionicframework.com')}>*/}
      {/*  <IonLabel>Showcase</IonLabel>*/}
      {/*</IonItem>*/}
      {/*<IonItem button onClick={() => close('https://github.com/ionic-team/ionic')}>*/}
      {/*  <IonLabel>GitHub Repo</IonLabel>*/}
      {/*</IonItem>*/}
      <IonItem button onClick={() => close(supportURL)}>
        <IonLabel>Support</IonLabel>
      </IonItem>
    </IonList >
  )
}

export default AboutPopover;
