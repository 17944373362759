import React, {useState} from 'react';
import {IonButton, IonFooter, IonHeader, IonModal, IonSegment, 
  IonSegmentButton, IonToolbar, IonItem,IonGrid,IonRow,IonCol, IonContent, IonIcon} from '@ionic/react';
import {calendarOutline, timeOutline} from 'ionicons/icons';
import moment from 'moment';
import './TimeOptions.scss';

/**
 * @type React.FC
 */
export const TimeOptions = ({presentingElement, ...props}) => {
  const [anchor, setAnchor] = useState(props.anchor);
  const [date, setDate]     = useState(props.date);
  const [time, setTime]     = useState(props.time);

  const now   = moment().format(moment.HTML5_FMT.TIME);
  const today = moment().format(moment.HTML5_FMT.DATE);

  const onTimeChange = e => {
    setTime(e.target.value); 
    if (!date) {
      setDate(today);
    }
  };
  const onDateChange = e => {
    setDate(e.target.value);
    if (!time) {
      setTime(now);
    }
  };
  const onReset      = () => {
    setDate(null);
    setTime(null);
  };

  return (
    <IonModal cssClass='time-options'
              isOpen={true}
              onDidDismiss={props.close}
              presentingElement={presentingElement}
              swipeToClose={true}>
      <IonHeader>
        <IonToolbar>
          <IonSegment value={anchor} onIonChange={(e) => setAnchor(e.detail.value)}>
            <IonSegmentButton value='departAfter'>
              Depart at
            </IonSegmentButton>
            <IonSegmentButton value='arriveBefore'>
              Arrive by
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="prevent-keyboard">
        <IonGrid>
          <IonRow/>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="8">
              <IonItem>
                <IonIcon className="date-time-icon" icon={calendarOutline}/>
                <label className="stacked-label">Date
                <input type="date" min={today} onChange={onDateChange} required  value={date ?? today}/>
                </label>
              </IonItem>
            
            </IonCol>
            <IonCol size="8">
            <IonItem>
              <IonIcon className="date-time-icon" icon={timeOutline}/> 
              <label className="stacked-label">Time
              <input min={!date || date === today ? now : '00:00'}
                     onChange={onTimeChange}
                     required
                     type="time"
                     step='60'
                     value={time ?? now}/>
              </label>
            </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol/>
            <IonCol color="primary">
              <IonButton disabled={!date && !time} fill='clear' onClick={onReset}>Reset to now</IonButton>
            </IonCol>
            <IonCol/>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton slot='start' fill='clear' onClick={props.close}>Cancel</IonButton>
          <IonButton slot='end' onClick={() => {
            props.setOptions({anchor, date, time});
            props.close();
          }}>Set</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
