import React from 'react';
import { IonContent, IonSlides, IonSlide } from '@ionic/react';
import './Tutorial.scss';

interface OwnProps {
  slideRef: any,
  SlideButtons: any
}

interface TutorialProps extends OwnProps { }

const TutorialWaverley: React.FC<TutorialProps> = ({ slideRef, SlideButtons }) => {

  return (
    <IonContent fullscreen forceOverscroll={false}>
      <IonSlides ref={slideRef} pager={true} scrollbar={true} options={{ initialSlide: 0 }}>
        <IonSlide>
          <div className="tutorial-slide">
            <h2 className="slide-title">Welcome to <b>Waverley</b></h2>
            <img src="assets/img/tutorial/waverley/TUTORIAL-1.svg" alt="" className="slide-image" />
            <p>The <b>Waverley Transport app</b> is a preview of mobility-as-a-service in action, and a demonstration of sustainable transport.</p>
          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>

        <IonSlide>
          <div className="tutorial-slide">
            <h2 className="slide-title">What is MaaS?</h2>
            <img src="assets/img/tutorial/waverley/TUTORIAL-2.svg" alt="" className="slide-image" />
            <p>
              <b>MaaS (Mobility as a Service)</b> is the concept that all travel options can be accessed through a single platform or app, allowing customers to have a seamless travel experience.
            </p>
          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>

        <IonSlide>
          <div className="tutorial-slide">
            <h2 className="slide-title">What is sustainable transport?</h2>
            <img src="assets/img/tutorial/waverley/TUTORIAL-3.svg" alt="" className="slide-image" />
            <p>
              <b>Sustainable Transport</b> is affordable, operates fairly and efficiently, offers a choice of transport mode, and supports a competitive economy.
            </p>
          </div>
          <SlideButtons isFinalSlide={false} />
        </IonSlide>

        <IonSlide>
          <div className="tutorial-slide">
            <h2 className="slide-title">Ready to Ride?</h2>
            <img src="assets/img/tutorial/waverley/TUTORIAL-4.svg" alt="" className="slide-image" />
          </div>
          <SlideButtons isFinalSlide={true} />
        </IonSlide>
      </IonSlides>
    </IonContent>
  );
};

export default TutorialWaverley;
