/*

This is the proxy / augmenter to augment wheelchair accessibility data for Analytics
Each implementation of transport service should have all behaviors described in this file.

This proxy is also used to achieve run time polymorphism to hold region specific implementation based on app configuration.

*/
import {ATransportService} from 'services/ATransportService';

import {
  sendEvent,
  GA_CATEGORY_UNKNOWN_ACCESSIBILITY,
  GA_ACTION_ROUTE,
  GA_ACTION_STOP,
  GA_CATEGORY_WHEELCHAIR_INACCESSIBLE,
} from 'components/Analytics'
import {WheelchairAccessibilityEnum} from './WheelchairAccessibilityEnum';

/**
 * Augment response with analytics related parameters in segment.
 * Input response will be as is response from TripGo or it will be after augmenting using UKWheelchairProxy for Scotland
 */
const WHEELCHAIR_ACCESSIBILITY_INACCESSIBLE = 0;
const WHEELCHAIR_ACCESSIBILITY_UNKNOWN = 1;
const WHEELCHAIR_ACCESSIBILITY_PARTIAL = 2;
const WHEELCHAIR_ACCESSIBILITY_ACCESSIBLE = 3;


const augmentAccessibilityData = function (responseJSON) {
  
  const isPublicTransit = (segment) => {
    return segment.template.modeInfo.identifier.startsWith("pt_");
  };
  const summarise = ( accessibilities ) => {
    return (accessibilities.reduce((journeyAccessibility, accessibility) => {
      // if any part is inaccessible, the whole segment is inaccessible
      if (journeyAccessibility === WheelchairAccessibilityEnum.INACCESSIBLE || accessibility === WheelchairAccessibilityEnum.INACCESSIBLE) {
        return WheelchairAccessibilityEnum.INACCESSIBLE;
      }
      // if any part is unknown, the whole segment is unknown (unless it's already inaccessible)
      if (journeyAccessibility === WheelchairAccessibilityEnum.UNKNOWN || accessibility === WheelchairAccessibilityEnum.UNKNOWN) {
        return WheelchairAccessibilityEnum.UNKNOWN;
      }            
      // if any part is partial, the whole journey is partial (unless it's already inaccessible)
      if (journeyAccessibility === WheelchairAccessibilityEnum.PARTIAL || accessibility === WheelchairAccessibilityEnum.PARTIAL) {
        return WheelchairAccessibilityEnum.PARTIAL;
      }
      return WheelchairAccessibilityEnum.ACCESSIBLE;
    }, WheelchairAccessibilityEnum.ACCESSIBLE));
  } 

  responseJSON?.groups?.forEach(group => {
    if(group.trips?.length > 0) {
      const trip = group.trips[0];  // pick first trip as this data is sorted by weighted score in TripGoTransportServiceImpl

      const inaccessibleStops = [];
      
      trip.segments?.filter(isPublicTransit).forEach(segment => {
        const stopss = segment.template.shapes?.filter(shape => shape.travelled).map(shape => shape.stops)
        const boardingStop = stopss[0][0];
        const lastShape = stopss[stopss.length - 1];
        const alightingStop = lastShape[lastShape.length - 1];

        // log wheelchair accessibility unknown or inaccessible at Google Analytics
        const sendEventHelper = (wheelchairAccessibility, action, label) => {
          if (wheelchairAccessibility === undefined) {
            sendEvent(GA_CATEGORY_UNKNOWN_ACCESSIBILITY, action, label);
          } else if (wheelchairAccessibility === WheelchairAccessibilityEnum.INACCESSIBLE) {
            sendEvent(GA_CATEGORY_WHEELCHAIR_INACCESSIBLE, action, label);
          }
        };

        sendEventHelper(segment.vehicleWheelchairAccessibility, GA_ACTION_ROUTE, segment.routeId);
        sendEventHelper(boardingStop.wheelchairAccessibility, GA_ACTION_STOP, boardingStop.code);
        sendEventHelper(alightingStop.wheelchairAccessibility, GA_ACTION_STOP, alightingStop.code);

        // set accessibility parameters so this can be used while showing segment accessibility in ResultGroup component
        segment.boardingStopAccessibility = boardingStop.wheelchairAccessibility;
        segment.alightingStopAccessibility = alightingStop.wheelchairAccessibility;

        // get all inaccessbile stops
        stopss.forEach(shape => {
          shape.forEach(stop => {
            if (stop.wheelchairAccessibility === WheelchairAccessibilityEnum.INACCESSIBLE) inaccessibleStops.push(stop.code)              
          });
        });
        
        segment.wheelchairAccessibility = summarise([segment.boardingStopAccessibility, segment.vehicleWheelchairAccessibility, segment.alightingStopAccessibility]);
      });
      trip.wheelchairAccessibility = summarise(trip.segments?.filter(isPublicTransit).map(segment => segment.wheelchairAccessibility));
      group.tripOrder = (trip.wheelchairAccessibility === WheelchairAccessibilityEnum.ACCESSIBLE ? WHEELCHAIR_ACCESSIBILITY_ACCESSIBLE 
        : trip.wheelchairAccessibility === WheelchairAccessibilityEnum.PARTIAL ? WHEELCHAIR_ACCESSIBILITY_PARTIAL : trip.wheelchairAccessibility === WheelchairAccessibilityEnum.UNKNOWN ? WHEELCHAIR_ACCESSIBILITY_UNKNOWN : WHEELCHAIR_ACCESSIBILITY_INACCESSIBLE )
      group.inaccessibleStops = inaccessibleStops;
    };
  });
}

export class AccessibilityProxyServiceImpl extends ATransportService {
  // Internally call getRouting of the service being proxied and augment response before sending
  getRouting = async (fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops = []) => {
    const responseJSON = await this.transportService.getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops);
    augmentAccessibilityData(responseJSON);
    return responseJSON;
  }

  // getLocations and getDeparture is not overridden in this class so both from parent Abstract class will be used

}
