import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {ErrorBoundary, init} from "@sentry/react";

init({
  allowUrls: ['waverley.smartcitiestransport.com'],
  dsn:       "https://d9454ba6b3d5438fb81d4b9a4bb4fab6@o430655.ingest.sentry.io/5399197",
  debug:     process.env.NODE_ENV === "development",
});

ReactDOM.render(
  <ErrorBoundary fallback={"An error has occured"}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);
