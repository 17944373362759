import {init, get, set} from './storage';
import {defaultV2Favourites} from 'data/apps/config'
const FAVOURITES_V1_REGION = "AU_NSW_Sydney";
const FAVOURITES_V2_KEY = "favouritesV2";
// migrated users to v2 on 2021-03-24
const FAVOURITES_V1_KEY = "favourites";

const migrateV1ToV2 = v1FavValue => {
  const stopCodes = [];
  // considering only stop codes with value as true
  Object.keys(v1FavValue).forEach(stopCode => v1FavValue[stopCode] && stopCodes.push(stopCode));
  return stopCodes.length ? {[FAVOURITES_V1_REGION]: stopCodes} : null;
};

// This favourites v2 structure and data is created on March 21, 2021
init(FAVOURITES_V2_KEY, defaultV2Favourites
  , FAVOURITES_V1_KEY, migrateV1ToV2,
);

// have to use stop.code not stop.stopCode to be consistent with what is given to departures.json

/** @return {boolean} */
export const add = (region, code) => {
  const favourites = get(FAVOURITES_V2_KEY);
  const currentRegionCodes = favourites[region] || [];
  currentRegionCodes.push(code);
  favourites[region] = currentRegionCodes;
  set(FAVOURITES_V2_KEY, favourites);
  return true;
};

/** @return {boolean} */
export const remove = (region, code) => {
  const favourites   = get(FAVOURITES_V2_KEY);
  favourites[region] = favourites[region].filter(favouriteCode => favouriteCode !== code);
  if (favourites[region].length === 0) {
    delete favourites[region];
  }
  set(FAVOURITES_V2_KEY, favourites);
  return false;
};

/** @return {object} */
export const getAll = () => {
  return get(FAVOURITES_V2_KEY);
}

/** @return {boolean} */
export const isEmpty = () => {
  const all = getAll();
  console.assert(all, 'there should always be an object');
  return !all || Object.keys(all).length === 0;
}

/** @return {boolean} */
export const includes = (region, code) => {
  const favourites = get(FAVOURITES_V2_KEY);
  return favourites[region]?.includes(code);
}
