import 'mapbox-gl/dist/mapbox-gl.css';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import ReactDOM from 'react-dom';
import {IonButton, IonIcon, useIonViewDidEnter, useIonViewWillEnter} from '@ionic/react';
import {compass} from 'ionicons/icons';
import {withRouter} from 'react-router';

import '../util/XMLHttpRequest';
import Geocoder from '../util/Geocoder';
import LayerControlReact from './LayerControl';
import mapboxgl from '../util/mapbox-gl';
import {SetAnimatedContext} from "../util/contexts";
import {initialMapBounds} from '../data/apps/config'
import {onGeolocateError, onGeolocateSuccess} from "../util/geolocate";

import './Map.scss';

const EMPTY_GEOJSON = {features: [], type: 'FeatureCollection'};

const STYLE_ACTIVE = 'mapbox://styles/mapbox/outdoors-v11';
const STYLE_ROADS  = 'mapbox://styles/mapbox/navigation-day-v1';
const STYLE_OTHER  = 'mapbox://styles/mapbox/light-v10';

const stylesMap = {};
const stylesMapElement = {};
let mapInfoContainer;

class InfoControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group', 'mapboxgl-ctrl-info');
    mapInfoContainer = this.container;
    return this.container;
  }

  // noinspection JSUnusedGlobalSymbols
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const InfoWindow = ({container, result}) => {
  if (container) {
    const info = result && <>
      <div className='mapboxgl-ctrl-info-place-name'>{result.result.place_name}</div>
      <div className='mapboxgl-ctrl-info-actions'>
        <IonButton
          // `routerLink` sucks.  it won't let you pass query parameters, either as an object or in the string, because it encodes the ?
          // also be careful, because something decodes the %20's (spaces) but not %2C's (commas) before being passed to you component
          // `href` doesn't have these problems, but href triggers a reload and doesn't navigate like an SPA
          routerLink={`/tabs/directions?toInput=${encodeURIComponent(result.result.place_name)}&toCentre=${encodeURIComponent(
            result.result.center)}`}
          >
          <IonIcon icon={compass} style={{verticalAlign: 'sub'}}/> Directions
        </IonButton>
      </div>
    </>;
    return ReactDOM.createPortal(info, container);
  }
  return <></>;
}

class LayerControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group', 'mapboxgl-ctrl-layer');
    ReactDOM.render(<LayerControlReact map={map}/>, this.container);
    return this.container;
  }

  // noinspection JSUnusedGlobalSymbols
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const Map = ({bounds, directions = EMPTY_GEOJSON, location, zoomOutTop = false, usesRoads, usesActiveTransport}) => {
  const history = useHistory();
  const setAnimated = React.useContext(SetAnimatedContext);
  const mapStyle = usesRoads ? STYLE_ROADS : usesActiveTransport ? STYLE_ACTIVE : STYLE_OTHER;
  const zoomOutTopRef = React.useRef(zoomOutTop);
  zoomOutTopRef.current = zoomOutTop;
  const containerElementResolveRef = React.useRef(() => {
  });
  const containerElementPromiseRef =
    React.useRef(new Promise(resolve => containerElementResolveRef.current = resolve));

  const showMap = React.useCallback((containerElement, mapElement, map) => {
    containerElement?.then(containerElement => {
      if (containerElement) {
        if (mapElement) {
          containerElement.insertAdjacentElement('beforeend', mapElement);
          mapElement.classList.add('show-map');
          map.resize();
        }
      }
    });
  }, []);

  const containerCallback = useCallback((/** @type {HTMLDivElement} */containerElement) => {
    if (containerElement) {
      containerElementResolveRef.current(containerElement);
      if (stylesMapElement[mapStyle]) {
        showMap(containerElementPromiseRef.current, stylesMapElement[mapStyle], stylesMap[mapStyle]);
      } else {
        const mapEle = document.createElement('div');
        mapEle.classList.add('map-canvas');
        containerElement.insertAdjacentElement('beforeend', mapEle);

        const map = new mapboxgl.Map({
          bounds: initialMapBounds,
          container: mapEle,
          preserveDrawingBuffer: true,
          style: mapStyle,
        });

        map.addControl(new InfoControl(), 'bottom-right');

        const geolocateControl = new mapboxgl.GeolocateControl({trackUserLocation: true});
        geolocateControl.on('error', onGeolocateError);
        geolocateControl.on('geolocate', onGeolocateSuccess);
        map.addControl(geolocateControl, 'bottom-right');

        const geocoder = new Geocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: 'Search here',
        });
        geocoder.on('result',
                    (result) => {
                      setAnimated(false);
                      history.replace(history.location.pathname, {...history.location.state, result});
                      setAnimated(true);
                    });
        map.addControl(geocoder);

        map.addControl(new LayerControl());

        map.once('idle', () => {
          mapEle.classList.add('show-map');
          map.resize();

          map.addSource('directions', {
            data: directions,
            type: 'geojson',
          });
          map.addLayer({
            id: 'directions',
            'layout': {
              'line-cap': 'round',
              'line-join': 'round',
            },
            'paint': {
              'line-color': ['get', 'rgb'],
              'line-opacity': ['get', 'opacity'],
              'line-width': {
                "stops": [
                  // zoom is 13 -> line width will be 2px
                  [13, 2],
                  // zoom is 16 -> line width will be 4px
                  [16, 4],
                ],
              },
            },
            source: 'directions',
            type: 'line',
          });
        });

        let mapSize = JSON.stringify(mapEle.getBoundingClientRect().toJSON());
        setInterval(() => {
          const rect = mapEle.getBoundingClientRect();
          const newMapSize = JSON.stringify(rect.toJSON());
          if (rect.height !== 0 && rect.width !== 0 && mapSize !== newMapSize) {
            map.resize();
          }
          mapSize = newMapSize;
        }, 100);

        stylesMapElement[mapStyle] = mapEle;
        stylesMap[mapStyle] = map;
      }
    } else {
      // probably unmounting, let's detach the map element before it's gone forever
      if (stylesMapElement[mapStyle]) {
        stylesMapElement[mapStyle].parentElement?.removeChild(stylesMapElement[mapStyle]);
      }
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const map = stylesMap[mapStyle];
    if (map) {
      map.once('idle', () => map.getSource('directions').setData(directions));
    }
  }, [stylesMap[mapStyle], directions])

  React.useEffect(() => {
    const map = stylesMap[mapStyle];
    if (map && bounds) {
      map.once('idle', () => {
        const zoomedBounds = new mapboxgl.LngLatBounds(bounds).toArray();
        const padding = {bottom: 20, left: 20, right: 20, top: 20};
        if (zoomOutTopRef.current) {
          zoomedBounds[0][1] = 2 * zoomedBounds[0][1] - zoomedBounds[1][1];
          padding.bottom = 40;
        }
        map.fitBounds(zoomedBounds, {padding});
      });
    }
  }, [stylesMap[mapStyle], bounds])

  React.useEffect(() => {
    const map = stylesMap[mapStyle];
    if (map) {
      const bounds = map.getBounds().toArray();
      const centre = map.getCenter().toArray();
      if (zoomOutTop) {
        // zoom "out" by moving south
        centre[1] = (centre[1] + bounds[0][1]) / 2;
      } else {
        // zoom "in" by moving north
        centre[1] = (centre[1] + bounds[1][1]) / 2;
      }
      // noinspection JSUnusedGlobalSymbols
      map.panTo(centre, {duration: 250, easing: t => (1 - Math.cos(t * Math.PI)) / 2});
    }
  }, [stylesMap[mapStyle], zoomOutTop])

  const showMap2 = () => showMap(containerElementPromiseRef.current, stylesMapElement[mapStyle], stylesMap[mapStyle]);
  React.useEffect(showMap2, [stylesMapElement[mapStyle], stylesMap[mapStyle], showMap]);
  useIonViewWillEnter(showMap2);
  useIonViewDidEnter(showMap2);

  return (
    <>
      <div ref={containerCallback}/>
      <InfoWindow container={mapInfoContainer} result={location?.state?.result} history={history}/>
    </>
  );
};

export default withRouter(Map);
