import React from 'react';

import Geocoder, {YOUR_LOCATION} from '../util/Geocoder';
import {accessToken} from '../util/mapbox-gl';
import {getGeolocation, hasGeolocatePermission} from "../util/geolocate";
import {geocoderProximityCoordinate} from '../data/apps/config';

import './Geocoder.scss';

function localGeocoder(query) {
  if (YOUR_LOCATION.toLowerCase().includes(query.toLowerCase())) {
    return [{
      geolocate: true,
      geometry: null,
      id: '0',
      place_name: YOUR_LOCATION,
      properties: {},
      type: 'Feature',
    }];
  }
}

// isVisible is used instead of unmounting so the component keeps its state when switching to favourites tab
export default React.forwardRef(function GeocoderComponent({isVisible, onResult, placeholder}, ref) {
  const geocoderRef                                     = React.useRef(null);
  const relocateRef                                     = React.useRef(async () => ({location: null}));  // no location, no error
  const [isGeocoderElementSet, setIsGeocoderElementSet] = React.useState(false);

  // we want things that depend on the div to be in useCallback, as that is after the div is available
  const geocoderCallback = React.useCallback(geocoderElement => {
    // will be null when unmounting
    if (geocoderElement) {
      geocoderRef.current.addTo(geocoderElement);
      // these depend on addTo
      geocoderRef.current.setPlaceholder(placeholder);
      if (hasGeolocatePermission()) {
        geocoderRef.current._inputEl.value = YOUR_LOCATION;
        relocateRef.current                = getGeolocation;
        relocateRef.current().then(onResult);
      }
      setIsGeocoderElementSet(true)
    }
    // callback refs run when dependencies change, or the ref is mounted/unmounted
    // we only want to run this callback ref once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we want things that need to be undone in useEffect, as that is where we can return a cleanup function
  React.useEffect(() => {
    const onGeocoderResult = (result) => {
      relocateRef.current = () => {
        if (result.result.geolocate) {
          return getGeolocation();
        } else {
          const location = result.result.geometry.coordinates;
          return Promise.resolve({location});
        }
      };
      relocateRef.current().then(onResult);
    }

    geocoderRef.current.on('result', onGeocoderResult);
    return () => geocoderRef.current.off('result', onGeocoderResult);
  }, [onResult])

  // in case placeholder changes
  React.useEffect(() => {
    if (isGeocoderElementSet) {
      // this depends on addTo, that's why we have `isGeocoderElementSet`
      geocoderRef.current.setPlaceholder(placeholder);
    }
  }, [placeholder]);

  React.useImperativeHandle(ref, () => ({
    relocate: relocateRef.current,
  }));

  // make sure this is initialized before the effect or callback run
  if (!geocoderRef.current) {
    geocoderRef.current = new Geocoder({
      accessToken,
      localGeocoder,
      minLength:   0,
      placeholder: 'Choose starting point',
      proximity:   geocoderProximityCoordinate,
    });
    //set proximity by location if we have user's location permission
    if (hasGeolocatePermission()) {
      getGeolocation().then(locationOrError => {
        if (locationOrError.location) {
          geocoderRef.current.setProximity(locationOrError.location);
        }
        // else if error, leave proximity untouched
      });
    }
  }

  return <div className='geocoder-container' data-is-visible={isVisible} ref={geocoderCallback}/>
});
