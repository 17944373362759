import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as debounce from 'lodash.debounce';

import {geocoderBBox, geocoderProximityCoordinate} from '../data/apps/config';

/**
 * I don't have time to create my own autocomplete widget, so I
 * mess with the internals of MapboxGeocoder to do what I want:
 * - close the keyboard after choosing a result
 * - listen for `input` events instead of `keydown`, because `keydown` doesn't fire on chrome on android
 * - open search results when focussing on input field
 *
 * Internals of MapboxGeocoder:
 * - _inputEl - the <input> element
 * - _onKeyDown - the keydown event listener that triggers a search
 * - _typeahead - the instance of `suggestions`: http://tristen.ca/suggestions/demo/
 * - _geocode - the function that does the search
 */
export default class Geocoder extends MapboxGeocoder {
  constructor(options) {
    super({bbox: geocoderBBox, ...options});

    // close the keyboard after choosing a result
    this.on('result', () => this._inputEl.blur());
  }

  onAdd(...args) {
    // listen for `input` events instead of `keydown`, because `keydown` doesn't fire on chrome on android
    const addEventListener                 = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, ...args) {
      if (type !== 'keydown') {
        // the debouncing causes the options to open when a result is tapped
        addEventListener.call(this, type, ...args);
      }
    };
    const el = super.onAdd(...args);
    EventTarget.prototype.addEventListener = addEventListener;
    this._inputEl.addEventListener('input', debounce(this._onKeyDown, 200));

    // open search results when focussing on input field
    this._inputEl.addEventListener('focus', () => {
      // does funny things on my android phone
      // this._inputEl.select();

      if (this._typeahead.data.length) {
        // if there was a list before, just display it, so the user can quickly choose a different result
        this._typeahead.update(this._typeahead.data);
      } else {
        // otherwise, get and show results
        const localGeocoderOnly        = this.options.localGeocoderOnly;
        this.options.localGeocoderOnly = !this._inputEl.value || this._inputEl.value === YOUR_LOCATION;
        if (!this.options.localGeocoderOnly || this.options.localGeocoder)
          this._geocode(this._inputEl.value);
        this.options.localGeocoderOnly = localGeocoderOnly;
      }
    });

    return el;
  }

  _updateProximity() {
    // proximity is designed for local scale, if the user is looking at the whole world,
    // it doesn't make sense to factor in the arbitrary centre of the map
    if (!this._map) {
      return;
    }
    if (this._map.getZoom() > 9) {
      var center = this._map.getCenter().wrap();
      this.setProximity([center.lng, center.lat]);
    } else {
      this.setProximity(geocoderProximityCoordinate);
    }
  }
}
export const YOUR_LOCATION = 'Your location';
