/*
  Trello card: https://trello.com/c/c9YO2wvx/297-rail-stations-with-partial-accessibility
  This proxy service is to override wheelchair accessibility status from TripGo for 'Redfern Station Platform 6' and 'Redfern Station Platform 7'

*/

import {ATransportService} from 'services/ATransportService'
import {WheelchairAccessibilityEnum} from './WheelchairAccessibilityEnum';

const augmentStopData = function (responseJSON) {

  /*
    Based on below note we are overriding accessibility for these two stops.
    Redfern Station (Sydney) platforms 6 & 7 are accessible - platforms 1-10 will be soon (probably in 2022)
  */
  const setStopWheelchairAccessibility = stop => {
    // 2015136 - Redfern Station Platform 6. 2015137 - Redfern Station Platform 7
    if(stop.code === '2015136' || stop.code === '2015137') {
      stop.wheelchairAccessibility = WheelchairAccessibilityEnum.ACCESSIBLE;
    }
  }
  const setShapeWheelchairAccessibility = shape => shape.stops?.forEach(setStopWheelchairAccessibility);

  const setSegmentTemplateWheelchairAccessibility = segmentTemplate => segmentTemplate.isPublicTransit && segmentTemplate.shapes?.forEach(setShapeWheelchairAccessibility);

  // process each segment template
  responseJSON?.segmentTemplates?.forEach(setSegmentTemplateWheelchairAccessibility)

}


const augmentDepartureData = function (responseJSON) {

  /*
    Inline function to check if input object has a type either bus or train and no wheelchairAccessibility information available
    Then it does a lookup against bus/rail stop code to see if wheelchair accessibility info is available within a map created using UK/Scotland wheelchair accessibility data.
  */
  const checkWheelchairAccessibleByCodeAndType = object => {
    const code = object.code || object.stopCode;
    // 2015136 - Redfern Station Platform 6. 2015137 - Redfern Station Platform 7
    if(code === '2015136' || code === '2015137') {
      object.wheelchairAccessibility = WheelchairAccessibilityEnum.INACCESSIBLE;
    }
  }

  /*
    Augmenting departure data for response.stops and embarkationStops
    this is used to show data on Schedule page
  */
  responseJSON?.stops?.forEach(stop => {
    checkWheelchairAccessibleByCodeAndType(stop);
    stop.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  });

  /*
    Augmenting parentInfo based on its stop type and stop code.
    parentInfo is not available for all departures, it was seen while searching for "glasgow central station" in UK_SouthScotland region
    This piece of code looks up at parentInfo as well as at its children and for both tries to augment it in the exact same way
  */
  if (responseJSON?.parentInfo) {
    const parentInfo = responseJSON.parentInfo;
    checkWheelchairAccessibleByCodeAndType(parentInfo);
    parentInfo.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  }

  /*
    Augmenting parentStops based on its stop type and stop code.
    parentStops is not available for all departures, it was seen while searching for "glasgow central station" in UK_SouthScotland region
    This piece of code looks up at parentStop as well as at its children and for both tries to augment it in the exact same way
  */
  responseJSON?.parentStops?.forEach(parentStop => {
    checkWheelchairAccessibleByCodeAndType(parentStop);
    parentStop.children?.forEach(checkWheelchairAccessibleByCodeAndType);
  });

}

export class NSWheelchairProxy extends ATransportService {

  // Internally call getRouting of the service being proxied and augment response before sending
  getRouting = async (fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops = []) => {
    const responseJSON = await this.transportService.getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops);
    augmentStopData(responseJSON);
    return responseJSON;
  }

  // getLocations is not overridden in this class so getLocations of parent Abstract class will be used

  // Internally call getDeparture of the service being proxied and augment response before sending
  getDeparture = async (embarkationStops, favourites) => {
    const responseJSON = await this.transportService.getDeparture(embarkationStops, favourites);
    augmentDepartureData(responseJSON);
    return responseJSON;
  }

}
