import React from 'react';

export default function AboutHeaderWaverley() {
  return <>
     <p className="ion-padding-start ion-padding-end">
      The Waverley Transport app was developed in response to
      the <a href='https://opendata.transport.nsw.gov.au/waverley-transport-innovation-challenge'>Waverley
      Transport Innovation Challenge</a>.<br/>
      <br/>
      Our solution is a customer facing MaaS app (iOS, Android, and mobile web) that:
    </p>
    <ul>
      <li>encourages the use of designated safe pick-up/drop-off zones,</li>
      <li>enables multimodal trip planning that will increase transport options for customers,</li>
      <li>integrates both public transport and privately owned shared transport service providers,</li>
      <li>provides safe and continuous routes for prams, wheelchairs, and children on bicycles,</li>
      <li>provides a range of journey options for a defined trip that includes total journey route, cost, and
          time,
      </li>
      <li>calculates driving, cycling, and walking directions to the final destination including where a trip
          contains multiple segments and modes, and
      </li>
      <li>provides electric vehicle (EV) charging points within a journey plan.</li>
    </ul>
  </>
}