import React, {useState} from "react";
import {IonRefresher} from "@ionic/react";

import './JynRefresher.css';

export interface HTMLJynRefresherElement extends HTMLIonRefresherElement {
  /**
   * Call `activateRefresher()` when your async operation has started. For example, the `refreshing` state is while the app is performing an asynchronous operation, such as receiving more data from an AJAX request. Once the data request has been start, you then call this method to signify that the refreshing has started and to open the refresher. This method does not change the refresher's state to `refreshing`.
   */
  "activateRefresher": () => Promise<void>;
}

// ionic doesn't have a way to make a refresher look like it's refreshing, so we hack it
export default React.forwardRef(function ({children, onIonRefresh, ...props}, ref) {
  const ionRefresherRef = React.useRef<HTMLIonRefresherElement>();
  // complete() breaks the refresher if run on a programmatic refresh
  const [isPulled, setIsPulled] = useState(false);

  function doRefresh() {
    setIsPulled(true);
    onIonRefresh(...arguments);
  }

  React.useImperativeHandle(ref, () => {
    // so all other methods (cancel, getProgress, etc.) are inherited
    const newRef = Object.create(ionRefresherRef.current);
    Object.setPrototypeOf(newRef, Object.getPrototypeOf(ionRefresherRef.current));

    newRef!.activateRefresher = async () => {
      // md classes when refresher is idle:       md refresher-md hydrated refresher-native
      // md classes when refresher is refreshing: md refresher-md hydrated refresher-native refresher-active refresher-refreshing

      // ios classes when refresher is idle:       ios refresher-ios hydrated
      // ios classes when refresher is refreshing: ios refresher-ios hydrated refresher-active refresher-refreshing
      // ios style applied on content during refresh: transition-duration: 280ms; transform: translateY(60px) translateZ(0px); overflow: hidden;
      ionRefresherRef.current!.classList.add("refresher-active", "refresher-refreshing");
    };

    // `complete` isn't writable so we have to use `defineProperty`
    // we need to override `complete` because if we call it when we activate the refresher, it breaks the refresher.  we can only call `super.complete()` when the user pulls to refresh.  we need `isPulled` to keep track if the user pulled to refresh or not.
    Object.defineProperty(newRef, 'complete', {
      value: async function () {
        //using Remove refresh class outside if/else so that it runs every time on .complete() call
        ionRefresherRef.current?.classList.remove("refresher-active", "refresher-refreshing");
        if (isPulled) {
          setIsPulled(false);
          // if we call .complete() with a programmatic activation, it breaks the refresher.
          // so we can only call it when the user pulls down.
          return await ionRefresherRef.current!.complete.call(ionRefresherRef.current!, ...arguments);
        }
      },
    });

    return newRef;
  });

  return <IonRefresher onIonRefresh={doRefresh} {...props} ref={ionRefresherRef}>{children}</IonRefresher>;
});
