import React from 'react';
import {RouteComponentProps, withRouter, useLocation} from 'react-router';

import {IonCheckbox, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, isPlatform} from '@ionic/react';
import {compassOutline, happyOutline, informationCircleOutline, mapOutline, timeOutline, bugOutline, informationCircle, helpCircle, settings} from 'ionicons/icons';

import {connect} from '../data/connect';
import {setDarkMode} from '../data/user/user.actions';
import './Menu.css'
import {useStopListFilterState} from 'util/stop-list-filter';
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {useTransportOptionsState} from 'util/transport-options';
const routes = {
  appPages: [
    {icon: mapOutline, path: '/tabs/map', title: 'Map'},
    {icon: compassOutline, path: '/tabs/directions', title: 'Directions'},
    {icon: timeOutline, path: '/tabs/schedule', title: 'Stops'},
    // { title: 'Speakers', path: '/tabs/speakers', icon: peopleOutline },
    {icon: informationCircleOutline, path: '/tabs/about', title: 'About'},
  ],
  loggedInPages: [
    // { title: 'Account', path: '/account', icon: person },
    // { title: 'Support', path: '/support', icon: help },
    // { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    // { title: 'Login', path: '/login', icon: logIn },
    // { title: 'Support', path: '/support', icon: help },
    // { title: 'Signup', path: '/signup', icon: personAdd }
  ],
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {
  // props coming from parent component
  isBottomMenuEnabled: boolean;
  setIsBottomMenuEnabled: (arg0: boolean) => void;
}

const Menu: React.FC<MenuProps> = ({
  darkMode,
  history,
  isAuthenticated,
  setDarkMode,
  setIsBottomMenuEnabled,
  menuEnabled,
  isBottomMenuEnabled,
}) => {
  const location = useLocation();
  const menuRef = React.useRef<HTMLIonMenuElement>();
  const buildDate = new Date(Number(process.env.REACT_APP_BUILD_DATE)).toLocaleDateString();

  // shared state to manage show wheelchair accessibility option
  const [shouldShowWheelchairAccessibility, setShouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();
  const [filter] = useStopListFilterState();
  const [transportOptions] = useTransportOptionsState();

  React.useEffect(() => {
    if(filter.wheelChairAccessibility !== 'all'){
      setShouldShowWheelchairAccessibility(true)
    }
  // custom hooks: https://github.com/facebook/react/issues/16873
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.wheelChairAccessibility])

  React.useEffect(() => {
    if(transportOptions.route === "wheelchair"){
      setShouldShowWheelchairAccessibility(true)
    }
  // custom hooks: https://github.com/facebook/react/issues/16873
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportOptions.route])

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => {
        const selected = location.pathname.startsWith(p.path);
        return (
          <IonMenuToggle key={p.title} auto-hide="false">
            <IonItem detail={false}
                     routerLink={!selected ? p.path : undefined}
                     routerDirection="none"
                     className={selected ? 'selected' : undefined}>
              <IonIcon slot="start" icon={p.icon}/>
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        );
      });
  }

  const onFeedbackClick = async () => {
    // specific for this section, user will open side menu first and then user can report via ybug
    // below line close side menu/drawer first then open ybug preference
    await menuRef.current?.close();
    Ybug.open('annotate');
  };

  return (
    <IonMenu contentId="main" disabled={!menuEnabled} ref={menuRef} type="overlay">
      <IonContent forceOverscroll={false}>
       <div className="menu-list-container">
        <IonList lines="none">
          <IonListHeader>
            <IonIcon icon={informationCircle}/>
            Information
            </IonListHeader>

          {renderlistItems(routes.appPages)}
        </IonList>
        {/*<IonList lines="none">*/}
        {/*  <IonListHeader>Account</IonListHeader>*/}
        {/*  {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}*/}
        {/*  <IonItem>*/}
        {/*    <IonIcon slot="start" icon={moonOutline}></IonIcon>*/}
        {/*    <IonLabel>Dark Mode</IonLabel>*/}
        {/*    <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />*/}
        {/*  </IonItem>*/}
        {/*</IonList>*/}
        <IonList lines='none'>
          <IonListHeader>
            <IonIcon icon={settings}/>
            Settings
            </IonListHeader>
          <IonItem>
          <IonCheckbox color="secondary" checked={shouldShowWheelchairAccessibility} slot="start" onIonChange={e => setShouldShowWheelchairAccessibility(e.detail.checked)}/>
            <IonLabel className="menu-items-label">Show Wheelchair Accessibility</IonLabel>
          </IonItem>
          <IonItem hidden={isPlatform('desktop')}>
            <IonCheckbox color="secondary" checked={isBottomMenuEnabled} slot="start" onIonChange={(e) => setIsBottomMenuEnabled(e.detail.checked)}/>
            <IonLabel>Show bottom menu</IonLabel>
          </IonItem>
        </IonList>
        <IonList lines="none">
          <IonListHeader>
            <IonIcon icon={helpCircle}/>
            Help
            </IonListHeader>
          <IonItem button onClick={onFeedbackClick}>
            <IonIcon slot="start" icon={bugOutline}/>
            Report a Bug
          </IonItem>
          <IonItem button onClick={() => {
            history.push('/tutorial');
          }}>
            <IonIcon slot="start" icon={happyOutline}/>
            Welcome
          </IonItem>
        </IonList>
       </div>
       <IonLabel className="last-updated-date">Last Updated On: {buildDate}</IonLabel>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
  }),
  mapDispatchToProps: ({
    setDarkMode,
  }),
  component: withRouter(Menu),
})
