import {timeZone} from "data/apps/config";

export function getStringFromSeconds(seconds) {
  // >= 99:30:00 -> 4d4h
  if (seconds >= 100 * 60 * 60 - 60 * 60 / 2) {
    const h = Math.round(seconds / 60 / 60);
    return Math.floor(h / 24) + "d" + h % 24 + "h";
  }
  // >= 04:59:30 -> 5h
  if (seconds >= 5 * 60 * 60 - 60 / 2) {
    const h = Math.round(seconds / 60 / 60);
    return h + "h";
  }
  // >= 00:99:30 -> 1h40m
  if (seconds >= 100 * 60 - 60 / 2) {
    const m = Math.round(seconds / 60);
    return Math.floor(m / 60) + "h" + m % 60 + "m";
  }
  // >= 00:04:59.500 -> 5m
  if (seconds >= 5 * 60 - 1. / 2) {
    const m = Math.round(seconds / 60);
    return m + "m";
  }
  // >=  00:00:99.500 -> 1m40s
  if (seconds >= 100 - 1. / 2) {
    const s = Math.round(seconds);
    return Math.floor(s / 60) + "m" + s % 60 + "s";
  }
  // >= 00:00:04.9995 -> 5s
  if (seconds >= 5 - 1. / 2000) {
    const s = Math.round(seconds);
    return s + "s";
  }
  // < 00:00:04.9995 -> 4999ms
  const ms = Math.round(seconds * 1000);
  return ms + "ms";
}

export const hourMinuteFormat = new Intl.DateTimeFormat(undefined,
                                                        {hour: 'numeric', minute: 'numeric', timeZone: timeZone});

export const hourMinuteSecondFormat = new Intl.DateTimeFormat(undefined,
                                                              {hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: timeZone});
