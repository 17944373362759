import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
} from '@ionic/react';
import {reloadOutline, reloadSharp} from 'ionicons/icons';
import {isPermissionDenied} from "../util/geolocate";
import {geocoderBBoxName} from "../data/apps/config";

export const GeolocationErrorCard = ({geolocationError, onclickFunction}) => {
  return (
    <IonCard className="error-card">
      <IonCardContent>
        {isPermissionDenied(geolocationError) ?
          'Your location is needed to provide stops near your location' :
          geolocationError.message}
      </IonCardContent>
      <IonItem>
        <IonButton onClick={onclickFunction} slot="end">
          <IonIcon ios={reloadOutline} md={reloadSharp}/>&nbsp;Try again
        </IonButton>
      </IonItem>
    </IonCard>
  )
}

export const OutofMetroCard = ({isOutofArea, pageName}) => {
  if (!isOutofArea) {
    return null;
  } else {
    return (
      isOutofArea &&
      <IonCard className="error-card">
        <IonCardContent>
          {pageName} are only available in {geocoderBBoxName}
        </IonCardContent>
      </IonCard>
    )
  }
}

export const UserErrorCard = ({response}) => {
  if (response.usererror) {
    return (
      <IonCard>
        <IonCardContent>
          {response.error}
        </IonCardContent>
      </IonCard>
    )
  }
  return null;
}
