import mapboxgl from 'mapbox-gl';
import {isPlatform} from '@ionic/react';

export default mapboxgl;

export const ios = isPlatform('ios');
export const accessToken = ios ?
                           'pk.eyJ1IjoiamF5ZW5hc2hhciIsImEiOiJja2NldGE3MTgwYnFsMnJrazJyNWd5am15In0.FKXA990dyFzsB5TCU9ea8Q' :
                           'pk.eyJ1IjoiamF5ZW5hc2hhciIsImEiOiJjazZuM25tZWQwdWpsM2RvN2Jhcnd0OWNzIn0.F7ORcsbd9ohL4B1NHgrSMg';

mapboxgl.accessToken     = accessToken;
