import React, { useRef } from 'react';
import { IonHeader, IonButton, IonIcon, useIonViewWillEnter } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { setMenuEnabled } from '../data/sessions/sessions.actions';
import './Tutorial.scss';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { TutorialContent } from 'data/apps/config';

import IonPage from './JynPage';

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled;
}

interface TutorialProps extends OwnProps, DispatchProps { }

const Tutorial: React.FC<TutorialProps> = ({ history, setMenuEnabled }) => {
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  useIonViewWillEnter(() => {
    setMenuEnabled(false);
  })

  // reset to slide 0 for next time the tutorial is opened
  const handlestartApp = () => slideRef.current?.slideTo(0);

  const startApp = async () => {
    await setMenuEnabled(true);
    //Used function here Because of @typescript-eslint/no-unused-expressions error
    handlestartApp()
    history.push('/tabs/map', { direction: 'none' });
  };

  // used to get to next slide
  const handleNext = () => slideRef.current?.slideNext();

  //SlideButtons Props
  interface SlideButtonsProps {
    isFinalSlide: Boolean;
  }

  const SlideButtons = ({ isFinalSlide }: SlideButtonsProps) => {
    return (
      <div className='slide-buttons'>
        {!isFinalSlide ?
          <><IonButton style={{ color: "grey", float: "left" }} fill="clear" onClick={startApp}>Skip</IonButton>
            <IonButton style={{ float: "right" }} fill="clear" onClick={handleNext}>Next<IonIcon icon={arrowForward} /></IonButton></>
          :
          <IonButton style={{ float: "right" }} fill="clear" onClick={startApp}>
            Continue
          <IonIcon slot="end" icon={arrowForward} />
          </IonButton>}
      </div>
    )
  }

  return (
    <IonPage id="tutorial-page" title="Tutorial">
      <IonHeader no-border>
      </IonHeader>
      <TutorialContent slideRef={slideRef} SlideButtons={SlideButtons} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: ({
    setMenuEnabled
  }),
  component: Tutorial
});
