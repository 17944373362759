import {SharedStorageStateTarget} from 'util/storage';
import {initialConnectingModesForTransportOption, initialRouteForTransportOption} from 'data/apps/config'

const TRANSPORT_OPTIONS = new SharedStorageStateTarget('transport-options', {
  /** @type string[] */
  connectingModes: initialConnectingModesForTransportOption,
  /** @type string[] */
  preferredModes: [],
  route: initialRouteForTransportOption,
});
export const useTransportOptionsState = () => TRANSPORT_OPTIONS.useSharedState()
