/*

This is the abstract class to provide contract for transport services used in this project.
Each implementation of transport service can have partial behaviors overridden for reasons like augmentation/pre validation.

This Abstract class is also used to achieve run time polymorphism to hold region specific implementation based on app configuration.

So to start with, it should have its own
*/
export class ATransportService {

  transportService;

  constructor(t) {
    this.transportService = t;
  }

  /**
   * Gives route data
   * @param {String} fromParam The from parameter
   * @param {String} anchor The anchor parameter
   * @return {RoutingResponse} the routing response
   */

  // TODO: change this from multiple arguments to a single object argument
  getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops = []) {
    if(this.transportService.getRouting) {
      return this.transportService.getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops);
    }
    this._WARNING('getRouting(fromParam, anchor, unix, toParam, modeParams, avoidModesParam, routeParam, neverAllowStops)');
  };

  getLocations(lng, lat, radius) {
    if(this.transportService.getLocations) {
      return this.transportService.getLocations(lng, lat, radius);
    }
    this._WARNING('getLocations(lng, lat, radius)');
  };

  getDeparture(embarkationStops, favourites) {
    if(this.transportService.getDeparture) {
      return this.transportService.getDeparture(embarkationStops, favourites);
    }
    this._WARNING('getDeparture(embarkationStops, favourites)');
  };

  // delcare a warning generator to notice if a method of the interface is not overridden
  // Needs the function name of the Interface method or any String that gives you a hint ;)
  _WARNING(fName = 'unknown method') {
    console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
  }
}
