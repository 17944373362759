import React, {useState} from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';

import {stopModes} from '../data/apps/config';

const getCheckboxItem = (state, setState) => ({label, value}) => {
  const onIonChange = e => {
    if (e.detail.checked) {
      setState([...state, value]);
    } else {
      setState(state.filter(stateValue => stateValue !== value));
    }
  };

  return <IonItem key={value}>
    <IonLabel>{label}</IonLabel>
    <IonCheckbox checked={state.includes(value)}
                 onIonChange={onIonChange}
                 slot='start'/>
  </IonItem>
};

/**
 * @type React.FC
 */
export default function StopListFilter({onDismissModal, setFilter, ...props}) {
  const [modes, setModes]   = useState(props.modes);
  const [radius, setRadius] = useState(props.radius);

  const [wheelChairAccessibility, setWheelChairAccessibility] = useState(props.wheelChairAccessibility);

  const getModeItem = getCheckboxItem(modes, setModes);


  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Filter Stops/Services
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content">
        <IonList>
          <IonListHeader><IonLabel>Distance</IonLabel></IonListHeader>
          <IonItem>
            <IonLabel>Metres</IonLabel>
            <input min={500}
                   max={2000}
                   onBlur={e => setRadius(e.target.valueAsNumber < 500 ? 500 : e.target.valueAsNumber < 2000 ? e.target.valueAsNumber : 2000)}
                   onChange={e => setRadius(e.target.valueAsNumber)}
                   required
                   slot='end'
                   type="number"
                   value={radius}/>
          </IonItem>
        </IonList>
        <IonList>
          <IonListHeader><IonLabel>Modes</IonLabel></IonListHeader>
          {stopModes.map(getModeItem)}
        </IonList>
        <IonList>
          <IonListHeader><IonLabel>Wheelchair accessibility</IonLabel></IonListHeader>
          <IonRadioGroup onIonChange={e => setWheelChairAccessibility(e.detail.value)} value={wheelChairAccessibility}>
            <IonItem>
              <IonLabel>Accessible only</IonLabel>
              <IonRadio slot="start" value="accessible-only"/>
            </IonItem>
            <IonItem>
              <IonLabel>Accessible or maybe accessible</IonLabel>
              <IonRadio slot="start" value="not-inaccessible"/>
            </IonItem>
            <IonItem>
              <IonLabel>All</IonLabel>
              <IonRadio slot="start" value="all"/>
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton slot='start' fill='clear' onClick={onDismissModal}>Cancel</IonButton>
          <IonButton slot='end' onClick={() => {
            setFilter({modes, radius, wheelChairAccessibility});
            onDismissModal();
          }}>Set</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
