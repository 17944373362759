import React from 'react'

//icons
import {ReactComponent as WheelChairAccessible} from 'assets/img/accessibility/wheelchair-accessible-outline.svg';
import {ReactComponent as WheelchairInAccessible} from 'assets/img/accessibility/wheelchair-inaccessible-outline.svg';
import {ReactComponent as WheelChairAccessibleUnknown} from 'assets/img/accessibility/wheelchair-unknown-outline.svg';
import {ReactComponent as WheelChairAccessiblePartial} from 'assets/img/accessibility/wheelchair-partial-outline.svg';
import {ReactComponent as WheelChair} from 'assets/img/accessibility/accessible.svg';

//styles
import './WheelChairAccessibilityStyle.scss'
import {WheelchairAccessibilityEnum} from 'services/impl/WheelchairAccessibilityEnum';

// isAccessible attribute will manage if accessibility status is true, false or undefined

export function WheelChairAccessibilityIcon({wheelchairAccessibility}) {
  return wheelchairAccessibility === WheelchairAccessibilityEnum.PARTIAL ? <WheelChairAccessiblePartial className="icon" width={20} height={20}/>
    :wheelchairAccessibility === WheelchairAccessibilityEnum.ACCESSIBLE ? <WheelChairAccessible className="icon" width={20} height={20}/>
    :wheelchairAccessibility === WheelchairAccessibilityEnum.INACCESSIBLE ? <WheelchairInAccessible className="icon" width={20} height={20}/>
    :<WheelChairAccessibleUnknown className="icon" width={20} height={20}/>
}

export function WheelChairAccessibilityBanner({wheelchairAccessibility}) {
  return (
    <div className="accessible" data-accessible={wheelchairAccessibility}>
      <WheelChair className="icon"/>
      <span/>
    </div>
  )
}