import React, {useRef, useState} from 'react';

import {
  getConfig,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonModal,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {filterOutline, filterSharp, options} from 'ionicons/icons';

import * as favourites from "../util/favourites";
import Geocoder from '../components/Geocoder';
import IonPage from './JynPage';
import IonRefresher, {HTMLJynRefresherElement as HTMLIonRefresherElement} from '../components/JynRefresher';
import {GeolocationErrorCard, OutofMetroCard} from '../components/errorcards';
import StopList from "../components/StopList";
import StopListFilter from "../components/StopListFilter";
import {connect} from '../data/connect';
import {set} from "../util/storage";
import {inBBox} from "../data/apps/config";

import './SchedulePage.scss'
import {transportService} from 'data/apps/config';
import {useShouldShowWheelchairAccessibilityInfoState} from 'util/wheelChair-accessibility';
import {allModes, useStopListFilterState} from 'util/stop-list-filter';

interface OwnProps {
}

interface StateProps {
  mode: 'ios' | 'md'
}

interface DispatchProps {
}

type SchedulePageProps = OwnProps & StateProps & DispatchProps;

type StopListFilterType = {
  modes: string[],
  radius: number,
  wheelChairAccessibility: string,
};

type LocationOrError = {
  location: number[], error?: never
} | {
  location?: never, error: Error
}

const SchedulePage: React.FC<SchedulePageProps> = ({mode}) => {
  const [segment, setSegment] = useState<'nearest' | 'favourites'>(!favourites.isEmpty() ? 'favourites' : 'nearest');
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>();
  const [geolocationError, setGeolocationError] = useState<Error>(null);
  const [departures, setDepartures] = useState(null);
  const [searchText, setSearchText] = useState('');

  const isDeparturesNearestAndFavourites = useRef(false);
  const geocoderRef = useRef<Geocoder>();
  const pageRef = useRef<HTMLElement>();
  const location = useRef<number[]>(null);
  const locations = useRef([]);

  // shared state to manage show wheelchair accessibility option based on Sidemenu
  const [shouldShowWheelchairAccessibility] = useShouldShowWheelchairAccessibilityInfoState();


  const ios = mode === 'ios';

  const [filter, setFilter] = useStopListFilterState()
  const saveFilter = (nextFilter: StopListFilterType) => {
    if (filter.radius !== nextFilter.radius) {
      // make sure the setLocations effect runs
      isDeparturesNearestAndFavourites.current = false;
      // need to pass radius explicitly as it hasn't changed, yet
      nearestDepartures(nextFilter.radius);
    }
    setFilter(nextFilter);
  };

  function favDepartures(hasNearestResponse: boolean) {
    ionRefresherRef.current?.activateRefresher();
    // @ts-ignore
    const stops = [].concat.apply([], locations.current?.groups?.map(group => group?.stops || []) || []);

    (async function () {
      //   1 stops -> 3.084 seconds
      //   2 stops -> 2.656 seconds
      //   5 stops -> 2.6 seconds
      //  10 stops -> 5 seconds
      //  20 stops -> 3.777 seconds
      //  50 stops -> 4.665 seconds
      // 180 stops -> 8.309 seconds

      // Version 2.0 [March 21, 2021]
      const responseJSON = await transportService.getDeparture(stops, favourites.getAll());
      setDepartures(responseJSON);
      ionRefresherRef.current?.complete();
    })();
    if(hasNearestResponse){
      isDeparturesNearestAndFavourites.current = true;
    }
  }

  const nearestDepartures = async (radius: number = filter.radius) => {
    ionRefresherRef.current!.activateRefresher();
    if (location.current?.length === 2) {
      // check if current location is inside inBBox area or not. if its inside bBox  we call API. otherwise stop the loader.
      if (inBBox(location.current!)) {
        locations.current = await transportService.getLocations(...location.current, radius);
        favDepartures(true);
      } else {
        ionRefresherRef.current?.complete();
      }
    } else {
      isDeparturesNearestAndFavourites.current = true;
      // It can be null or false. both is accurate here. but It should be false in the first place, null is not making any sense here.
      favDepartures(false);
    }
  };

  const relocateThenGetNearest = (shouldAlwaysGetNearest = false) => {
    geocoderRef.current.relocate().then((locationOrError: LocationOrError) => {
      location.current = locationOrError.location;
      setGeolocationError(locationOrError.error);
      if (location.current || shouldAlwaysGetNearest)
        nearestDepartures();
      else
        ionRefresherRef.current!.complete();
    });
  };

  const setSegmentAndGetNearest = (segment) => setSegment(prevSegment => {
    // if changing to the nearest segment
    if (prevSegment !== 'nearest' && segment === 'nearest') {
      // if we haven't fetched nearest stops' departures, yet
      if (!isDeparturesNearestAndFavourites.current) {
        ionRefresherRef.current!.activateRefresher();
        // set to true now so switching segments quickly/repeatedly doesn't cause more calls
        isDeparturesNearestAndFavourites.current = true;
        relocateThenGetNearest();
      }
    }
    return segment;
  });

  // get favourite stops' departures on mount
  React.useEffect(() => {
    favDepartures(false);
  }, []);

  const doRefresh = () => {
    if ((location.current || geolocationError) && segment === 'nearest') {
      relocateThenGetNearest(true);
    } else {
      // if the user didn't set a location, we still want to trigger fetching departures
      favDepartures(false);
    }
    ionRefresherRef.current!.activateRefresher();
  };

  const onTryAgain = () => {
    ionRefresherRef.current!.activateRefresher();
    relocateThenGetNearest();
  };

  // @ts-ignore
  const onGeocoderResult = (geolocationOrError) => {
    // if geo permission is denied then close refresher
    if (geolocationOrError.error) {
      setGeolocationError(geolocationOrError.error);
      ionRefresherRef.current!.complete();
    } else {
      location.current = geolocationOrError.location
      setGeolocationError(null);
      // onGeocoderResult is called on mount, but we don't want to fetch the nearest stops' departures, yet
      // we should have already fetched the favourite stops' departures
      if (segment === "nearest")
        nearestDepartures();
    }
  }

  React.useEffect(() => {
    if(!shouldShowWheelchairAccessibility){
      const newFilter = {...filter, wheelChairAccessibility: 'all'}
      saveFilter(newFilter)
    }
  // custom hooks: https://github.com/facebook/react/issues/16873
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowWheelchairAccessibility])

  return (
    <IonPage ref={pageRef} id="schedule-page" title="Schedule Page">
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar &&
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
          }
          {!showSearchbar &&
            <IonTitle>Stops</IonTitle>
          }
          {showSearchbar &&
            <IonSearchbar showCancelButton="always"
                          placeholder="Filter by destination stop"
                          onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
                          onIonCancel={() => setShowSearchbar(false)}/>
          }

          <IonButtons slot="end">
            {!ios && !showSearchbar &&
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={filterSharp}/>
              </IonButton>
            }
            {!showSearchbar && segment === "nearest" &&
              <IonButton onClick={() => setShowFilterModal(true)}>
                {mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only"/>}
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>

        <IonToolbar>
          <IonSegment value={segment} onIonChange={(e) => setSegmentAndGetNearest(e.detail.value as any)}>
            <IonSegmentButton value="nearest">
              Nearest
            </IonSegmentButton>
            <IonSegmentButton value="favourites">
              Favourites
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>

      <IonContent fullscreen={true}>

        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent/>
        </IonRefresher>

        {/* keep the geocoder mounted so when we switch tabs, it maintains its state */}
        <Geocoder isVisible={segment === 'nearest'}
                  onResult={onGeocoderResult}
                  placeholder="Search for starting stop"
                  ref={geocoderRef}/>

        {ios &&
          <IonSearchbar onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
                        placeholder="Filter by destination stop"
                        searchIcon={filterOutline}/>
        }
        {geolocationError ? <GeolocationErrorCard geolocationError={geolocationError} onclickFunction={onTryAgain}/>:
        <OutofMetroCard isOutofArea={location.current && !inBBox(location.current!)} pageName='Stops'/>}
        <StopList departures={departures}
                  filter={filter}
                  geolocation={location.current}
                  searchText={searchText}
                  visibleRegionsStopCodes={segment === 'nearest' ? null : favourites.getAll()}/>
        {filter.modes.length < allModes.length && <IonItem className="change-alert"><IonLabel>
          Modes filtered out: {allModes.filter(mode => !filter.modes.includes(mode)).join(', ')}
        </IonLabel></IonItem>}
        {filter.wheelChairAccessibility !== 'all' && <IonItem className="change-alert"><IonLabel>Wheelchair accessibility:&nbsp;
            {(filter.wheelChairAccessibility === 'accessible-only') && ('Accessible only')}
          {(filter.wheelChairAccessibility === 'not-inaccessible') && ('Accessible or maybe accessible')}
        </IonLabel></IonItem>}
        {departures && <IonItem className="poweredby">
          <IonLabel>
            Powered by SkedGo/TripGo
          </IonLabel>
        </IonItem>}
      </IonContent>

      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="session-list-filter"
      >
        <StopListFilter
          onDismissModal={() => setShowFilterModal(false)}
          setFilter={saveFilter}
          {...filter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(SchedulePage),
  mapStateToProps: () => ({
    mode: getConfig()!.get('mode'),
  }),
});
